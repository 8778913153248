/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React from "react";
import {
  Row,
  Col,
  Input,
  Button,
  Form,
  Select,
  DatePicker,
  Upload,
  Radio,
} from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  checkAlphabets,
  checkNumbervalue,
  checkSpace,
  checkSpecialCharacter,
  firstLetterCapitalise,
} from "../../../utils";

import { getStaffOnboardClear } from "../../../actionsMethods/onboardingMethods";
import uploadLogo from "../../../assests/images/bx_upload.svg";

const { Dragger } = Upload;

function StaffForm({
  form,
  createPersonalDetails,

  disableDaysBeforeToday,
  minDate,
  phnOtpVerified,
  formResult,
  checkPhnNumExists,
  setPhnOtpVerified,
  setShowVerify,
  setVisible,
  sentPhoneOtp,
  showVerify,
  verifyEmailExists,
  setShowVerifyEmail,
  handleRoleChange,
  roleOptions,
  idTypes,
  handleSelectedId,
  roleSelected,
  driverSelected,
  dlClass,
  fileProps,
  handleChange,
  handleRemoveFile,
  handlePreview,
  beforeUpload,
  headers,

  handleRadioChange,
  showBank,
  capitalise,
  checkIFSC,
  selectedId,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dateFormat = "DD/MM/YYYY";

  const IDInput = () => {
    switch (selectedId) {
      case "pan":
        return (
          <Form.Item
            name="idNumber"
            label="PAN Card"
            rules={[
              {
                message: "Please enter pan number",
                required: true,
                pattern: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/g,
              },
            ]}
          >
            <Input
              placeholder="Enter pan number"
              maxLength={16}
              onKeyPress={(event) => {
                if (checkSpace(event) || checkSpecialCharacter(event)) {
                  event.preventDefault();
                }
              }}
              disabled={formResult?.idNumber || !selectedId ? true : false}
              onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
            />
          </Form.Item>
        );
      case "VOTER":
        return (
          <Form.Item
            name="idNumber"
            label="Voter ID"
            rules={[
              {
                message: "Please enter ID number",
                required: true,
                pattern: /^[A-Z]{3}[0-9]{7}$/g,
              },
            ]}
          >
            <Input
              placeholder="Enter Voter ID"
              maxLength={16}
              onKeyPress={(event) => {
                if (checkSpace(event) || checkSpecialCharacter(event)) {
                  event.preventDefault();
                }
              }}
              disabled={formResult?.idNumber || !selectedId ? true : false}
              onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
            />
          </Form.Item>
        );
      case "DL":
        return (
          <Form.Item
            name="idNumber"
            label="Driving licence"
            rules={[
              {
                message: "Please enter valid DL number",
                required: true,
              },
            ]}
          >
            <Input
              placeholder="Ex:KA32 20210011055"
              maxLength={16}
              style={{ textTransform: "uppercase" }}
              onKeyPress={(event) => {
                if (checkSpace(event) || checkSpecialCharacter(event)) {
                  event.preventDefault();
                }
              }}
              disabled={formResult?.idNumber || !selectedId ? true : false}
              // onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
            />
          </Form.Item>
        );

      default:
        return (
          <Form.Item
            name="idNumber"
            label="Aadhar number"
            rules={[
              {
                message: "Please enter aadhar number",
                required: true,
                pattern:
                  /^([0-9]{4}[0-9]{4}[0-9]{4}$)|([0-9]{4}\s[0-9]{4}\s[0-9]{4}$)|([0-9]{4}-[0-9]{4}-[0-9]{4}$)/g,
              },
            ]}
          >
            <Input
              placeholder="Enter aadhar number"
              maxLength={12}
              onKeyPress={(event) => {
                if (
                  checkSpecialCharacter(event) ||
                  checkSpace(event) ||
                  checkNumbervalue(event)
                ) {
                  event.preventDefault();
                }
              }}
              disabled={formResult?.idNumber || !selectedId ? true : false}
            />
          </Form.Item>
        );
    }
  };
  return (
    <Form
      className="global-form profile-container-form pl-4 pr-4 p-2"
      layout="vertical"
      form={form}
      scrollToFirstError={{
        behavior: "smooth",
        block: "center",
        inline: "center",
      }}
      onFinish={createPersonalDetails}
    >
      <Row gutter={40} className="global-form-row">
        <Col span={24} className="mb-1">
          <span className="master-title">Personal Details</span>
          <hr className="divider" />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="firstName"
            label="First name"
            rules={[
              {
                required: true,
                message: null,
              },
              () => ({
                validator(rule, value = "") {
                  if (value.length < 3) {
                    return Promise.reject("Please enter first name");
                  }
                  if (value.substring(1, 0) === " ") {
                    return Promise.reject("Please enter valid name");
                  } else {
                    return Promise.resolve();
                  }
                },
                validateTrigger: "onSubmit",
              }),
            ]}
            onKeyPress={(event) => {
              if (checkAlphabets(event)) {
                event.preventDefault();
              }
            }}
          >
            <Input
              placeholder="Please enter first name"
              maxLength={30}
              onInput={(e) => (e.target.value = firstLetterCapitalise(e))}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="lastName"
            label="Last name"
            rules={[
              {
                required: true,
                message: null,
              },
              () => ({
                validator(rule, value = "") {
                  if (value.length < 3) {
                  return Promise.reject("Please enter last name");
                  }
                  if (value.substring(1, 0) === " ") {
                  return Promise.reject("Please enter valid name");
                  } else {
                    return Promise.resolve();
                }
                },
              validateTrigger: "onSubmit",
        }),
            ]}
            onKeyPress={(event) => {
              if (checkAlphabets(event)) {
                event.preventDefault();
              }
            }}
          >
            <Input
              placeholder="Please enter last name"
              maxLength={30}
              onInput={(e) => (e.target.value = firstLetterCapitalise(e))}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="dateOfBirth"
            label="Date of birth"
            rules={[
              {
                required: true,
                message: "Please select date of birth",
              },
            ]}
          >
            <DatePicker
              format={dateFormat}
              disabledDate={disableDaysBeforeToday}
              defaultPickerValue={minDate}
            />
          </Form.Item>
        </Col>

        <Col xs={9} sm={9} md={9} lg={9} xl={9}>
          <Form.Item
            name="contactNumber"
            label="Contact number"
            hasFeedback={phnOtpVerified}
            validateStatus="success"
            rules={[
              {
                required: true,
                message: "Please enter contact number",
              },
            ]}
          >
            <Input
              onKeyPress={(e) => {
                if (/[^0-9]/.test(e.key)) {
                  e.preventDefault();
                }
                if (e.target.value.length >= 10) {
                  e.preventDefault();
                }
              }}
              placeholder="Enter contact number"
              maxLength={10}
              disabled={formResult !== null}
              onChange={(e) => {
                if (e.target.value.length === 10) {
                  checkPhnNumExists(e.target.value);
                } else {
                  // phnOtpVerified
                  setPhnOtpVerified(false);
                  setShowVerify(false);
                }
              }}
            />
          </Form.Item>
        </Col>
        {formResult === null ? (
          <Col xs={3} sm={3} md={3} lg={3} xl={3}>
            <Form.Item label={<span style={{ visibility: "hidden" }}>.</span>}>
              <Button
                className="btn-style cancel-btn"
                onClick={() => {
                  setVisible(true);
                  sentPhoneOtp();
                }}
                disabled={!showVerify}
              >
                VERIFY
              </Button>
            </Form.Item>
          </Col>
        ) : null}
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Form.Item
            name="alternativeNumber"
            label="Alternate contact no."
            rules={[
              {
                required: false,
                message: "Please enter a valid phone number",
              },
              {
                pattern: /^([1-9]{1}[0-9]{9}){0,10}$/g,
                message: "Invalid contact number",
              },
            ]}
            onKeyPress={(event) => {
              if (/[^0-9]/.test(event.key)) {
                event.preventDefault();
              }
              if (
                checkNumbervalue(event) ||
                checkSpecialCharacter(event) ||
                checkSpace(event)
              ) {
                event.preventDefault();
              }
            }}
          >
            <Input placeholder="Enter secondary phone number" maxLength={10} />
          </Form.Item>
        </Col>

        <Col xs={9} sm={9} md={9} lg={9} xl={9}>
          <Form.Item
            name="emailId"
            label="Email ID"
            type="email"
            rules={[
              {
                required: true,
                message: "Please enter a email id",
              },
              {
                type: "email",
                message: "The input is not valid E-mail",
                validateTrigger: "onSubmit",
              },
            ]}
            onKeyPress={(event) => {
              if (checkSpace(event)) {
                event.preventDefault();
              }
            }}
          >
            <Input
              placeholder="Enter email"
              maxLength={80}
              disabled={formResult !== null}
              onChange={(e) => {
                if (e.target.value.length > 10) {
                  verifyEmailExists(e.target.value);
                } else {
                  setShowVerifyEmail(false);
                }
              }}
              onInput={(e) => (e.target.value = e.target.value.toLowerCase())}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="role"
            label="Role"
            rules={[
              {
                message: "Please select role",
                required: true,
              },
            ]}
          >
            <Select
              onChange={handleRoleChange}
              placeholder="Select role"
              options={roleOptions}
              disabled={formResult !== null}
            />
          </Form.Item>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Form.Item
            name="idType"
            label="Select ID"
            rules={[
              {
                message: "Please select ID",
                required: formResult ? false : true,
              },
            ]}
          >
            <Select
              placeholder="Select ID"
              options={idTypes}
              onChange={handleSelectedId}
              disabled={formResult?.idNumber || !roleSelected ? true : false}
            />
          </Form.Item>
        </Col>
        {driverSelected && (
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              name="dlClass"
              label="DL Class"
              rules={[
                {
                  message: "Please select DL class",
                  required: formResult ? false : true,
                },
              ]}
            >
              <Select
                placeholder="Select DL class"
                mode="multiple"
                options={dlClass}
                // onChange={handleSelectedId}
                disabled={formResult?.idNumber || !roleSelected ? true : false}
              />
            </Form.Item>
          </Col>
        )}

        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <IDInput />
        </Col>

        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Form.Item
            name="idUrl"
            label="Upload file"
            rules={[
              {
                message: "Upload file",
                required: true,
              },
            ]}
          >
            <div className="one-row">
              <Dragger
                {...fileProps}
                className=""
                onChange={handleChange}
                onRemove={handleRemoveFile}
                // disabled={formResult?.idUrl}
                maxCount={1}
                onPreview={handlePreview}
                beforeUpload={beforeUpload}
                multiple={false}
                headers={headers}
              >
                <Row>
                  <Col
                    span={6}
                    className="m-auto"
                    style={{ borderRight: "1px solid #CECECE" }}
                  >
                    <img
                      src={uploadLogo}
                      className="Upload-logo"
                      style={{ width: "40px", height: "40px" }}
                    />
                  </Col>
                  <Col span={18} className="f-13">
                    <p>Click to upload or drag and drop</p>
                    {/* <p>Maximum file size 10 MB</p> */}
                    <p>
                      Supported file type: PDF, JPEG, PNG and Max 5MB file size
                    </p>
                  </Col>
                </Row>
              </Dragger>
            </div>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-1">
          <h3>Do you wish to add bank details of this staff</h3>
          <Form.Item name={"bankDetail"} label="">
            <Radio.Group defaultValue={false} onChange={handleRadioChange}>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>

        <div style={{ display: !showBank ? "none" : "contents" }}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="accountHolderName"
              label="Account holder’s name"
              rules={[
                {
                  message: "Please enter name",
                  required: showBank ? true : false,
                },
              ]}
              onKeyPress={(event) => {
                if (checkSpecialCharacter(event) || checkAlphabets(event)) {
                  event.preventDefault();
                }
              }}
            >
              <Input
                placeholder="Enter name"
                onInput={(e) => (e.target.value = capitalise(e))}
                // disabled={!bankRequired}
                maxLength={30}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="accountNumber"
              label="Account number"
              rules={[
                {
                  message: "Please enter account number",
                  required: showBank ? true : false,
                },
              ]}
            >
              <Input
                placeholder="Enter account number"
                // disabled={!bankRequired}
                maxLength={18}
                onKeyPress={(event) => {
                  if (
                    checkSpecialCharacter(event) ||
                    checkSpace(event) ||
                    checkNumbervalue(event)
                  ) {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="ifscCode"
              label="IFSC code"
              rules={[
                {
                  required: showBank ? true : false,
                  message: "Please enter IFSC code",
                },
                {
                  pattern: /^[A-Z]{4}0[A-Z0-9]{6}$/g,
                  message: "Please enter valid IFSC code",
                },
              ]}
              onKeyPress={(event) => {
                if (checkSpecialCharacter(event) || checkSpace(event)) {
                  event.preventDefault();
                }
              }}
            >
              <Input
                placeholder="Enter IFSC code"
                onChange={(event) => {
                  if (event.target.value.length > 10) {
                    checkIFSC(event.target.value);
                  } else {
                    form.setFieldsValue({
                      bankName: "",
                      branchName: "",
                    });
                  }
                }}
                maxLength={12}
                onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item name="bankName" label="Bank name">
              <Input
                placeholder="Please enter bank name"
                maxLength={30}
                disabled
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item name="branchName" label="Branch name">
              <Input placeholder="Enter branch name" maxLength={30} disabled />
            </Form.Item>
          </Col>
        </div>
      </Row>
      <hr className="divider" />

      <div className="d-flex flex-end mt-1">
        <Button
          className="btn-style cancel-btn ml-1 w-10"
          onClick={() => {
            dispatch(getStaffOnboardClear());
            form.resetFields();
            navigate("/onboarding-settings");
          }}
        >
          CANCEL
        </Button>
        <Button
          htmlType="submit"
          className={
            formResult === null && !phnOtpVerified
              ? "btn-style ml-1 w-10"
              : " save-btn ml-1"
          }
          disabled={formResult === null && !phnOtpVerified}
        >
          SUBMIT
        </Button>
      </div>
    </Form>
  );
}

export default StaffForm;
