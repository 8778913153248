// eslint-disable import/no-anonymous-default-export
export default {
  dev: {
    API: {
      nodeBaseURL:
        "https://pxnvsrtwy1.execute-api.ap-south-1.amazonaws.com/dev",
    },
    auth: {
      domain: "smcs-prod.hutechweb.com",
      oauthDomain: "SMCS_POOL.auth.us-east-1.amazoncognito.com",
      userPoolWebClientId: "5764umttfqiplob9e337ar0v0m",
      userPoolId: "us-east-1_TD8m0Yrs9",
      region: "us-east-1",
    },
  },
  qa: {
    API: {
      nodeBaseURL: "https://pxnvsrtwy1.execute-api.ap-south-1.amazonaws.com/qa",
    },
    auth: {
      domain: "qaapis.hubops.innofulfill.com",
      oauthDomain: "smcs.auth.ap-south-1.amazoncognito.com",
      userPoolWebClientId: "69nb6vpga3k7v9au0r6ioovpo8",
      userPoolId: "ap-south-1_z5sdmVKiT",
      region: "ap-south-1",
    },
  },
  uat: {
    API: {
      nodeBaseURL:
        "https://pxnvsrtwy1.execute-api.ap-south-1.amazonaws.com/uat",
    },
    auth: {
      domain: "smcs-uat.hutechweb.com",
      oauthDomain: "smcs.auth.ap-south-1.amazoncognito.com",
      userPoolWebClientId: "a3e0iaciccfeln7no5b467ab5",
      userPoolId: "ap-south-1_xoRMaTXJu",
      region: "ap-south-1",
    },
  },
  prod: {
    API: {
      nodeBaseURL:
        "https://co3e029tq9.execute-api.ap-south-1.amazonaws.com/prod",
    },
    auth: {
      domain: "smcs-prod.hutechweb.com",
      oauthDomain: "smcs.auth.ap-south-1.amazoncognito.com",
      userPoolWebClientId: "168ssb7bu4v71556pd1017nl62",
      userPoolId: "ap-south-1_NR41y4LSv",
      region: "ap-south-1",
    },
  },
};
