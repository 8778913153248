/* eslint-disable indent */
/* eslint-disable quotes */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Table,
  Col,
  Row,
  Select,
  Form,
  Spin,
  Input,
  Button,
  message,
} from "antd";
import axios from "axios";
import { APIs } from "../../../../utils/worker";
import { capitalize, capitaliseWithHyphen } from "../../../../utils";
import { LoadScript } from "@react-google-maps/api";
import { getPremiseId } from "../../../../utils/worker";
import constants from "../../../../constants/constants";
const { ROLES } = constants;
const { HO_ADMIN } = ROLES;
let userData = JSON.parse(localStorage.getItem("userinfo"));
import "./style.scss";
import { getHubListWithMode } from "./index.jsx";

const AddNewRoute = ({ handleCreateRoute, handleCloseModal, primaryMode }) => {
  const formLayout = "vertical";
  const [form] = Form.useForm();
  const { Option } = Select;

  const transitTimeOptions = [
    { label: 24, value: 24 },
    { label: 48, value: 48 },
    { label: 72, value: 72 },
    { label: 96, value: 96 },
  ];
  
  const transitMode = [
    { label: "Surface", value: "SURFACE" },
    { label: "Air", value: "AIR" },
  ];
  const transitModeBoth = [
    { label: "Surface", value: "SURFACE" },
    { label: "Air", value: "AIR" },
    { label: "Both", value: "BOTH" },
  ];
  let hubList = Array.from(getHubListWithMode().entries()); // Convert Map to Array of key-value pairs

  let hubListMap = new Map();

  // Iterate through hubList and add premiseId as key, hubMode as value
  hubList.forEach(([index, hub]) => {
    if (hub.premiseId && hub.hubMode !== undefined) {
      hubListMap.set(hub.premiseId, hub.hubMode);
    }
  });

  const findElegibleMode = (srcMode,destMode) => {
    if(srcMode==='Surface' || destMode==='Surface' || primaryMode==='SURFACE' || srcMode==='NA' || destMode==='NA' || srcMode==='' || destMode===''){
      return 'SURFACE';
    }
    else if((srcMode==='Air' || destMode==='Air')){
      return 'AIR';
    }else if(srcMode==='Both' && destMode==='Both'){
      return 'BOTH';
    }else{
      return 'SURFACE';
    }
  };

  function getTransitMode(input) {
    if (input === "BOTH") {
      return transitModeBoth;
    }
    const result = transitModeBoth.find((mode) => mode.value === input);
    return result ? [result] : [];
  }
  

  const [hubListWithMode, setHubListWithMode] = useState(hubListMap);
  const [selectedTransitMode, setSelectedTransitMode] = useState('');
  const [dynamicTransitMode, setDynamicTransitMode] = useState(transitMode);

  const [rowOptions, setRowOptions] = useState({transitMode}); // Holds dropdown options per row
  const [selectedTransitModes, setSelectedTransitModes] = useState(getTransitMode(primaryMode)); // Holds selected mode per row

  const options = [];
  const [transitForm, setTransitForm] = useState([]);
  const [hubDetails, setHubDetails] = useState([]);
  const [transitHubDetails, setTransitHubDetails] = useState([]);
  const [totalTAT, setTotalTAT] = useState(0);
  const [hubDetailsParent, setHubDetailsParent] = useState([]);
  const [originHub, setOriginHub] = useState("");
  const [originLatLng, setOriginLatLng] = useState({
    latitude: "",
    longitude: "",
  });
  const [destLatLng, setDestLatLng] = useState({ latitude: "", longitude: "" });
  const [destinationHub, setDestinationHub] = useState("");
  const [destinationTime, setDestinationTime] = useState("");
  const [tokenData, setToken] = useState("");
  const [transitValue, settransitValue] = useState(0);
  const [test, setTest] = useState(false);
  const [roles, setRoles] = useState();

  useEffect(() => {
    setRoles(JSON.parse(localStorage.getItem("userinfo")));
  }, []);

  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem("userinfo"));
    getHubDetails(userData.accessToken);
    setToken(userData.accessToken);
  }, []);
  useEffect(() => {
    changeTransitHubs();
  }, [transitValue, destinationHub]);

  const changeTransitHubs = () => {
    setTransitHubDetails(
      hubDetails.filter(
        (e) => e.value !== originHub && e.value !== destinationHub
      )
    );
  };
  const getHubDetails = async (token) => {
    let response = await axios.get(
      APIs.baseURL + "/premise-service/v1/premises",
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    let temp = [];
    response.data.map((item) => {
      temp.push({
        label: capitaliseWithHyphen(item?.premiseName),
        value: item?.premiseId,
        latitude: item?.latitude,
        longitude: item?.longitude,
      });
    });
    setHubDetails(temp);
    setHubDetailsParent(temp);
    roles && roles?.roles?.includes(HO_ADMIN)
      ? ""
      : setOriginHubDetails(temp, getPremiseId()?.split(" ")[1]);
  };

  const setOriginHubDetails = (a, b) => {
    let foundData = a?.find((item) => item?.value == b);
    if (foundData) {
      form.setFieldValue("destinationHub", null);
      form.setFieldValue("destinationTransitTime", null);
      setDestinationTime("");
      form.setFieldValue("totalTAT", null);
      form.setFieldValue("originHub", foundData?.value);
      setOriginHub(foundData?.value);
      setOriginLatLng({
        latitude: foundData?.latitude,
        longitude: foundData?.longitude,
      });
    }
  };

  const formProps = {
    name: "address",
    className: "register-form",
    onFinish: (values) =>
      handleCreateRoute(values, transitForm, destinationTime),
    autoComplete: "off",
  };

  const handleSelectChange = (value, name, x) => {
    let findLatLng = hubDetailsParent?.find((it) => it?.value === value);
    if (name === "destinationHub") {
      setDestinationHub(value);
      setDestLatLng({
        latitude: findLatLng?.latitude,
        longitude: findLatLng?.longitude,
      });
      // const google = window.google;
      // const directionsService = new google.maps.DirectionsService();
      // directionsService.route(
      //   {
      //     origin:
      //       transitForm.length > 0 && x === undefined
      //         ? new google.maps.LatLng(
      //             transitForm[transitForm.length - 1].latitude,
      //             transitForm[transitForm.length - 1].longitude
      //           )
      //         : new google.maps.LatLng(
      //             originLatLng.latitude,
      //             originLatLng.longitude
      //           ),
      //     destination: new google.maps.LatLng(
      //       findLatLng.latitude,
      //       findLatLng.longitude
      //     ),
      //     travelMode: google.maps.TravelMode.DRIVING,
      //   },
      //   (result, status) => {
      //     if (status === google.maps.DirectionsStatus.OK) {
      //       let distance = result.routes[0].legs[0].distance.text;
      //       let time = +distance.split(" ")[0].split(",").join("") / 40;
      //       let finalTime = (time / 4 + time).toFixed(1);
      //       let finalMinute = finalTime * 60;
      //       let actualTime = timeConvert(finalMinute);
      //       form.setFieldValue("destinationTransitDistance", distance);
      //       form.setFieldValue("destinationTransitTime", actualTime);
      //       setDestinationTime(finalTime);

      //       for (let i = 0; i < transitForm.length; i++) {
      //         form.setFieldValue("transitHub" + i, null);
      //         form.setFieldValue("transitTime" + i, null);
      //         form.setFieldValue("totalTAT", null);
      //       }
      //     } else {
      //       console.error("error fetching directions", result, status);
      //     }
      //   }
      // );
      fetchRouteServiceMaster(originHub,value);
      
    } else if (name === "originHub") {
      form.setFieldValue("destinationHub", null);
      form.setFieldValue("destinationTransitTime", null);
      setDestinationTime("");
      form.setFieldValue("totalTAT", null);
      setOriginHub(value);
      setOriginLatLng({
        latitude: findLatLng?.latitude,
        longitude: findLatLng?.longitude,
      });
      for (let i = 0; i < transitForm?.length; i++) {
        form.setFieldValue("transitHub" + i, null);
        form.setFieldValue("transitTime" + i, null);
      }
    }


    // if (name === "destinationTransitTime") {
    //   let total = 0;
    //   transitForm.map((e) => {
    //     total = total + +e.time;
    //   });
    //   setTotalTAT(total + value);
    //   form.setFieldValue("totalTAT", total + value);
    // }
  };
  const onChange = (value) => {
    // console.log(`selected ${value}`);
  };

  const changeTransitValue = (e) => {
    for (let i = 0; i < transitForm?.length; i++) {
      form.setFieldValue("transitHub" + i, null);
      form.setFieldValue("transitTime" + i, null);
      form.setFieldValue("transitDistance" + i, null);
      form.setFieldValue("destinationTransitTime", null);
      form.setFieldValue("destinationTransitDistance", null);
      form.setFieldValue("totalTAT", null);
    }
    let value = e.target.value;
    if (value > 7) {
      settransitValue(7);
    } else {
      settransitValue(value);
      let transitForm_ = [];
      for (let i = 0; i < value; i++) {
        transitForm_.push({
          hub: "",
          time: "",
          mode: primaryMode === "SURFACE" ? "Surface" : "Air",
          distance: "",
          final_distance: "",
          final_time: "",
        });
      }
      setTransitForm([...transitForm_]);
      setTest(!test);
    }
    if (value === "" || value == "0") {
      handleSelectChange(destinationHub, "destinationHub", "x");
    }
  };

  // console.log(getPremiseId().split(" ")[1]);

  function timeConvert(n) {
    var num = n;
    var hours = num / 60;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return rhours + " hour(s) " + rminutes + " minute(s)";
  }

  const changeTransit = async (data, index, name, nameVal) => {

    let findLatLng = hubDetailsParent.find((it) => it.value === data);
    // console.log(index, findLatLng, transitForm[1 - 1].latitude);
    const google = window.google;
    const directionsService = new google.maps.DirectionsService();
    directionsService.route(
      {
        origin:
          index === 0
            ? new google.maps.LatLng(
                originLatLng?.latitude,
                originLatLng?.longitude
              )
            : new google.maps.LatLng(
                transitForm[index - 1]?.latitude,
                transitForm[index - 1]?.longitude
              ),
        destination: new google.maps.LatLng(
          findLatLng?.latitude,
          findLatLng?.longitude
        ),
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (result, status) => {

        if (status === google.maps.DirectionsStatus.OK) {
          let distance = result.routes[0].legs[0].distance.text;

          let time = +distance.split(" ")[0].split(",").join("") / 40;
          let finalTime = (time / 4 + time).toFixed(1);
          let finalMinute = finalTime * 60;
          let actualTime = timeConvert(finalMinute);
          let transitForm_ = transitForm;
          form.setFieldsValue(nameVal, data);
          form.setFieldValue("transitDistance" + index, distance);
          form.setFieldValue("transitTime" + index, actualTime);
          transitForm_[index] = {
            ...transitForm_[index],
            [name]: data,
            distance: distance,
            time: finalTime,
            latitude: findLatLng?.latitude,
            longitude: findLatLng?.longitude,
            actualTime: actualTime,
            final_time: finalMinute,
            final_distance: +distance
              ?.replace("km", "")
              ?.trim()
              ?.replace(",", ""),
          };

          setTransitForm([...transitForm_]);
        } else {
          console.error("error fetching directions", result, status);
        }
      }
    );
    if (index === transitForm?.length - 1) {
      directionsService.route(
        {
          origin: new google.maps.LatLng(
            findLatLng?.latitude,
            findLatLng?.longitude
          ),
          destination: new google.maps.LatLng(
            destLatLng?.latitude,
            destLatLng?.longitude
          ),
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            let distance = result.routes[0].legs[0].distance.text;
            let time = +distance.split(" ")[0].split(",").join("") / 40;
            let finalTime = (time / 4 + time).toFixed(1);
            let finalMinute = finalTime * 60;
            let actualTime = timeConvert(finalMinute);
            form.setFieldValue("destinationTransitDistance", distance);
            form.setFieldValue("destinationTransitTime", actualTime);
            setDestinationTime(finalTime);
          } else {
            console.error("error fetching directions", result, status);
          }
        }
      );
    }
  };

  const transitData = () => {
    let design = [];
    transitForm?.map((item, index) => {
      let random = Math.random();
      design.push(
        <Row className="global-form-row" gutter={20} key={index}>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <Form.Item
              name={"transitHub" + index}
              label={"Transit " + (index + 1)}
              // initialValue={item.hub ? item.hub : "Select transit hub"}
              rules={[
                {
                  required: true,
                  message: "Please select transit hub",
                },
              ]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                placeholder="Select transit hub"
                options={transitHubDetails}
                onChange={(data) =>
                  // changeTransit(data, index, "hub", "transitHub" + index)
                  changeTransitNew(data, index, "hub", "transitHub" + index)
                }
                value={item.hub}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <Form.Item
              name={`transitMode${index}`}
              label={
                index === 0
                  ? "Origin to T" + (index + 1) + " Mode"
                  : "T" + index + " to " + "T" + (index + 1) + " Mode"
              }
              initialValue= {primaryMode}
              rules={[
                {
                  required: true,
                  message: "Please select mode",
                },
              ]}
            >
              <Select
                placeholder= "Select mode"
                disabled={primaryMode === "AIR" ? false : true}
                //options={dynamicTransitMode}
                //value={selectedTransitMode} 
                options={rowOptions[index] || []} 
                onChange={(data) =>
                  // changeTransit(data, index, "mode", `transitMode${index}`)
                  
                  changeTransitNew(data, index, "mode", `transitMode${index}`)
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <Form.Item
              name={`transitDistance${index}`}
              label={
                index === 0
                  ? "Origin to T" + (index + 1) + " Distance"
                  : "T" + index + " to " + "T" + (index + 1) + " Distance"
              }
              initialValue={item.distance}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <Form.Item
              name={`transitTime${index}`}
              label={
                index === 0
                  ? "Origin to T" + (index + 1) + " TAT"
                  : "T" + index + " to " + "T" + (index + 1) + " TAT"
              }
              // rules={[
              //   {
              //     required: true,
              //     message: "Please select time",
              //   },
              // ]}
            >
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
      );
    });
    return design;
  };

  const fetchRouteServiceMaster = async(sourceId,destinationId) => {
    let mode = findElegibleMode(hubListWithMode.get(sourceId),hubListWithMode.get(destinationId));
    let modeToShowInDrowpDown= mode;
    const updatedOptions = getTransitMode(mode);
    setDynamicTransitMode(updatedOptions);
    if(mode==='BOTH'){
      mode='SURFACE';
    }
    let payload = {
      "source":sourceId,
      "destination":destinationId,
      "type":"HUB",
      "mode": mode
    };
    
    try {
      let response = await axios.post(
        `${APIs.tatURL}`,
        payload,
        {
          headers: {
            "Authorization": userData?.accessToken,
            //token: token.split("Bearer ")[1],
            "clientname" : "HUBOPS_AUTH_KEY",
            "authtype" : "x-api-key",
            "x-api-key" : "61757468746f6b656e666f726875626f7073"

          },
        }
      );
      if (response?.status) {
        let distance = response?.data?.data?.totalDistance;
        let time = +response?.data?.data.totalTime / 40;
        let finalTime = (time / 4 + time).toFixed(1);
        let finalMinute = finalTime * 60;
        let actualTime = timeConvert(finalMinute);
        if(primaryMode==='AIR'){
          form.setFieldValue("destinationTransitMode" , modeToShowInDrowpDown);
          
        }
        
        form.setFieldValue("destinationTransitDistance", distance);
        form.setFieldValue("destinationTransitTime", actualTime);
        setDestinationTime(finalTime);
            for (let i = 0; i < transitForm.length; i++) {
              form.setFieldValue("transitHub" + i, null);
              
              form.setFieldValue("transitTime" + i, null);
              form.setFieldValue("totalTAT", null);
            }
      }
    } catch (error) {
      console.log(error);
      message.error(error.response.data.message);
      
    }
  };
  const changeTransitNew = async (data, index, name, nameVal) => {

    let findLatLng = hubDetailsParent.find((it) => it.value === data);
    let sourceHub = 0;
    if(index==0){
      sourceHub = originHub;
    }
    else{
      sourceHub = transitForm[index-1].hub;
    }

    let payload = {};
    let mode="";
    let newDefault={};
    let modeToShowInDrowpDown="";
    if(name==="hub")
    {
      mode = findElegibleMode(hubListWithMode.get(sourceHub),hubListWithMode.get(data));
      modeToShowInDrowpDown= mode;
      const updatedOptions = getTransitMode(mode);
     // setDynamicTransitMode(updatedOptions);
      setRowOptions((prevOptions) => {
        const newOptions = {
          ...prevOptions,
          [index]: updatedOptions,
        };
        return newOptions;
      });

      
      // Ensure selectedTransitMode is valid after options change
      newDefault = updatedOptions[0] || null;
      setSelectedTransitModes((prevModes) => {
        const updatedModes = {
          ...prevModes,
          [index]: newDefault,
        };
        return updatedModes;
      });
      
      setSelectedTransitMode(newDefault);
      if(mode==='BOTH'){
        mode='SURFACE';
      }
      payload = {
        "source":sourceHub,
        "destination":data,
        "type":"HUB",
        "mode":mode
      };
      sourceHub = data;
      //mode=primaryMode;
    }
    else{
      //const updatedOptions = hubListWithMode.get(transitForm[index].hub)==='Both' ? transitModeBoth : transitMode;
      //setDynamicTransitMode(updatedOptions);
      mode = findElegibleMode(hubListWithMode.get(sourceHub),data);
      modeToShowInDrowpDown= data;
      if(data==='BOTH'){
        mode='SURFACE';
      }
      else{
        mode=data;
      }      
      payload = {
        "source":sourceHub,
        "destination":transitForm[index].hub,
        "type":"HUB",
        "mode":mode
      };
      sourceHub=transitForm[index].hub;
      
    }

    try {
      let response = await axios.post(
        `${APIs.tatURL}`,
        payload,
        {
          headers: {
            "Authorization": userData?.accessToken,
            //token: token.split("Bearer ")[1],
            "clientname" : "HUBOPS_AUTH_KEY",
            "authtype" : "x-api-key",
            "x-api-key" : "61757468746f6b656e666f726875626f7073"

          },
        }
      );
      if (response?.status) {
        let distance = response?.data?.data?.totalDistance;
        let time = +response?.data?.data.totalTime / 40;
        let finalTime = (time / 4 + time).toFixed(1);
        let finalMinute = finalTime * 60;
        let actualTime = timeConvert(finalMinute);
        let transitForm_ = transitForm;
        form.setFieldsValue(nameVal, data);
        if(primaryMode==='AIR'){
          form.setFieldValue("transitMode" + index, modeToShowInDrowpDown);
          
        }
        
        form.setFieldValue("transitDistance" + index, distance + " km");
        form.setFieldValue("transitTime" + index, actualTime);
        transitForm_[index] = {
            ...transitForm_[index],
            [name]: data,
            distance: distance,
            time: finalTime,
            latitude: findLatLng?.latitude,
            longitude: findLatLng?.longitude,
            actualTime: actualTime,
            final_time: finalMinute,
            mode:modeToShowInDrowpDown
          };
        
        setTransitForm([...transitForm_]);
      }
    } catch (error) {
      console.log(error);
      message.error(error.response.data.message);
      
    }
    if (index === transitForm.length - 1) {
    mode = findElegibleMode(hubListWithMode.get(sourceHub),hubListWithMode.get(destinationHub));
    modeToShowInDrowpDown= mode;
    const updatedOptions = getTransitMode(mode);
    setDynamicTransitMode(updatedOptions);
    if(mode==='BOTH'){
      mode='SURFACE';
    }
      let payload = {
        "source":sourceHub,
        "destination":destinationHub,
        "type":"HUB",
        "mode":mode
      };
      try {
        let response = await axios.post(
          `${APIs.tatURL}`,
          payload,
          {
            headers: {
              "Authorization": userData?.accessToken,
              //token: token.split("Bearer ")[1],
              "clientname" : "HUBOPS_AUTH_KEY",
              "authtype" : "x-api-key",
              "x-api-key" : "61757468746f6b656e666f726875626f7073"
  
            },
          }
        );
        if (response?.status) {
          let distance = response?.data?.data?.totalDistance;
          let time = +response?.data?.data.totalTime / 40;
            let finalTime = (time / 4 + time).toFixed(1);
            let finalMinute = finalTime * 60;
            let actualTime = timeConvert(finalMinute);
            if(primaryMode==='AIR'){
              form.setFieldValue("destinationTransitMode" , modeToShowInDrowpDown);
              
            }
            
            form.setFieldValue("destinationTransitDistance", distance);
            form.setFieldValue("destinationTransitTime", actualTime);
            setDestinationTime(finalTime);
        }
      } catch (error) {
        console.log(error);
        message.error(error.response.data.message);
      }
    }
  };
  return (
    <div>
      <Spin spinning={false}>
        <Form
          {...formProps}
          form={form}
          layout={formLayout}
          className="route-table global-form"
        >
          <Row className={"global-form-row route-main"} gutter={20}>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item
                name="originHub"
                label="Origin Hub"
                rules={[
                  {
                    required: true,
                    message: "Please select origin hub",
                  },
                ]}
              >
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  placeholder="Select origin hub"
                  options={hubDetails}
                  onChange={(value) => handleSelectChange(value, "originHub")

                  }
                  disabled={
                    roles && roles?.roles?.includes(HO_ADMIN) ? false : true
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item
                name="destinationHub"
                label="Destination Hub"
                rules={[
                  {
                    required: true,
                    message: "Please select destination hub",
                  },
                ]}
              >
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  placeholder="Select destination hub"
                  options={hubDetails}
                  onChange={(value) =>
                    handleSelectChange(value, "destinationHub")
                  }
                />
              </Form.Item>
            </Col>
            <Col span={8}></Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item
                name="primaryMode"
                label="Primary mode of travel"
                rules={[
                  {
                    required: true,
                    message: "Please select mode",
                  },
                ]}
                initialValue={primaryMode}
              >
                <Select
                  className="disable-input"
                  disabled
                  placeholder="Select mode"
                  options={transitMode}
                  value={selectedTransitMode.value} // Default selected value
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={3} lg={3} xl={3}>
              <Form.Item name="noOfTransit" label="Add Transit">
                <Input
                  placeholder="0"
                  onChange={changeTransitValue}
                  value={transitValue}
                  onKeyPress={(e) => {
                    if (/[^0-7]/.test(e.key) || e.target.value.length === 1) {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={13}></Col>
          </Row>
          {transitData()}
          <Row gutter={20} className="global-form-row">
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item name="destinationHub" label="Destination Hub">
                <Select
                  placeholder="Select destination hub"
                  options={hubDetails}
                  disabled
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item
                name={`destinationTransitMode`}
                label={
                  transitForm.length > 0
                    ? "T" + transitForm.length + " to Destination Mode"
                    : "Origin to Destination Mode"
                }
                rules={[
                  {
                    required: true,
                    message: "Please select mode",
                  },
                ]}
                initialValue={capitalize(primaryMode)}
              >
                <Select
                  placeholder="Select mode"
                  disabled={primaryMode === "SURFACE" && true}
                  // disabled
                  options={dynamicTransitMode}
                  value={selectedTransitMode.value} // Default selected value
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item
                name={`destinationTransitDistance`}
                label={
                  transitForm.length > 0
                    ? "T" + transitForm.length + " to Destination Distance"
                    : "Origin to Destination Distance"
                }
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={4} xl={6}>
              <Form.Item
                name={`destinationTransitTime`}
                label={
                  transitForm.length > 0
                    ? "T" + transitForm.length + " to Destination TAT (in Hour)"
                    : "Origin to Destination TAT (in hour)"
                }
              >
                <Input disabled />
              </Form.Item>
            </Col>
            {/* <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item name="totalTAT" label="Total TAT" initialValue={0}>
                <Input disabled />
              </Form.Item>
            </Col> */}
          </Row>
          <hr className="divider mt-3" />
          <div className="d-flex flex-end" style={{ padding: "1rem 0 0 0" }}>
            <Button className="cancel-btn" onClick={handleCloseModal}>
              CANCEL
            </Button>
            <Button htmlType="submit" className="save-btn ml-1">
              SAVE
            </Button>
          </div>
          {/* <Row className="add-pin-footer">
            <Button className="btn-style cancel-btn w-10">Cancel</Button>

            <Button className="btn-style save-btn ml-1 w-10" htmlType="submit">
              Save
            </Button>
          </Row> */}
        </Form>
      </Spin>
    </div>
  );
};

export default AddNewRoute;
