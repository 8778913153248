// eslint-disable import/no-anonymous-default-export
export default {
  dev: {
    API: {
      // baseURL: "https://devhubopsapis.innofulfill.com/smcs-webapp",
      // bffBaseURL: "https://devhubopsapis.innofulfill.com",
      baseURL: "https://smcs-dev.hutechweb.com/smcs-webapp",
      bffBaseURL: "https://smcs-dev.hutechweb.com",
      tatURL: "https://devapis.delcaper.com/routemaster/hubEstimate",
      reportGustTokenURL:"https://qaapis.hubops.innofulfill.com/datazip/fetch-guest-token",
    },
    auth: {
      domain: "smcs-prod.hutechweb.com",
      oauthDomain: "SMCS_POOL.auth.us-east-1.amazoncognito.com",
      userPoolWebClientId: "5764umttfqiplob9e337ar0v0m",
      userPoolId: "us-east-1_TD8m0Yrs9",
      region: "us-east-1",
      datazip_userName:"datazip.embed",
      datazip_password:"JNF0D*fa$cd4",
    },
  },
  qa: {
    API: {
      baseURL: "https://qaapis.hubops.innofulfill.com/smcs-webapp",
      bffBaseURL: "https://qaapis.hubops.innofulfill.com",
      tatURL: "https://qaapis.delcaper.com/routemaster/hubEstimate",
      reportGustTokenURL:"https://qaapis.hubops.innofulfill.com/datazip/fetch-guest-token",
    },
    auth: {
      domain: "qaapis.hubops.innofulfill.com",
      oauthDomain: "smcs.auth.ap-south-1.amazoncognito.com",
      userPoolWebClientId: "69nb6vpga3k7v9au0r6ioovpo8",
      userPoolId: "ap-south-1_z5sdmVKiT",
      region: "ap-south-1",
      datazip_userName:"datazip.embed",
      datazip_password:"JNF0D*fa$cd4",
    },
  },
  uat: {
    API: {
      baseURL: "https://uatapis-hubops.innofulfill.com/smcs-webapp",
      bffBaseURL: "https://uatapis-hubops.innofulfill.com",
      tatURL: "https://qaapis.delcaper.com/routemaster/hubEstimate",
      reportGustTokenURL:"https://qaapis.hubops.innofulfill.com/datazip/fetch-guest-token",
    },
    auth: {
      domain: "smcs-uat.hutechweb.com",
      oauthDomain: "smcs.auth.ap-south-1.amazoncognito.com",
      userPoolWebClientId: "a3e0iaciccfeln7no5b467ab5",
      userPoolId: "ap-south-1_xoRMaTXJu",
      region: "ap-south-1",
      datazip_userName:"datazip.embed",
      datazip_password:"JNF0D*fa$cd4",
    },
  },
  prod: {
    API: {
      baseURL: "https://apis-hubops.innofulfill.com/smcs-webapp",
      bffBaseURL: "https://apis-hubops.innofulfill.com",
      tatURL: "https://apis.delcaper.com/routemaster/hubEstimate",
      reportGustTokenURL:"https://apis-hubops.innofulfill.com/datazip/fetch-guest-token",
    },
    auth: {
      domain: "smcs-prod.hutechweb.com",
      oauthDomain: "smcs.auth.ap-south-1.amazoncognito.com",
      userPoolWebClientId: "168ssb7bu4v71556pd1017nl62",
      userPoolId: "ap-south-1_NR41y4LSv",
      region: "ap-south-1",
      datazip_userName:"datazip.embed",
      datazip_password:"JNF0D*fa$cd4",
    },
  },
};
