/* eslint-disable indent */
/* eslint-disable quotes */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ReactComponent as Linked_Icon } from "../../../assests/images/SVG/linked-icon.svg";
import { useNavigate } from "react-router-dom";
import { Table, Button, Input, message, Menu, Dropdown, Spin, Drawer } from "antd";
import { RightOutlined, DownOutlined, EditOutlined } from "@ant-design/icons";
import { APIs } from "../../../utils/worker";
import { getAllHubOnboard } from "../../../actionsMethods/masterMethods";
import { serialNum } from "../../../utils";
import { camelCase } from "../../../utils";
import ExpandedHubScreen from "./ExpandedHubScreen";
import download_pin_icon from "../../../assests/images/SVG/download-pin.svg";
import print_logo from "../../../assests/images/SVG/master-print.svg";
import search_icon from "../../../assests/images/search-icon.svg";
import Smcs_Loader from "../../../assests/images/loader-new.gif";
import urls from "../../../api/urls";
import API from "../../../api";
import "./style.scss";
import EditHubOnboard from "./EditHubOnboard";
const { HUB_LIST_SEARCH, GET_EXPAND_HUB, GET_EXPAND_HUB_CP } = urls;
const ViewAllHub = ({ viewHubHandle, activeKey }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const [pageNum, setPageNum] = useState(0);
  const [totalPage, setTotalPage] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [inputSearchValue, setInputSearchValue] = useState("");
  const [inputToSearchValue, setInputToSearchValue] = useState("");
  const [allHubData, setAllHubData] = useState([]);
  const [recordData, setRecordData] = useState();
  const [allCpData, setAllCpData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [drawerWidth, setDrawerWidth] = useState(900);
  const [editData,setEditData]=useState();
  const getAllHubOnboardResponse = useSelector(
    (state) => state?.masterReducer?.getAllHubOnboardReducer,
    shallowEqual
  );
  const { fetching, result } = getAllHubOnboardResponse;
  useEffect(() => {
    if (result) {
      let temp = [];
      result?.hubList?.map((data) =>
        temp.push({
          ...data,
          key: data.premiseName,
        })
      );
      setAllHubData(temp);
      setTotalPage(result?.hubCount);
    }
  }, [result]);
  useEffect(() => {
    dispatch(getAllHubOnboard(currentPage, pageSize));
  }, []);
  useEffect(() => {
    if (activeKey === "2") {
      dispatch(getAllHubOnboard(currentPage, pageSize));
      setInputSearchValue("");
    }
  }, [activeKey]);
  useEffect(() => {
    setInputToSearchValue("");
    setInputSearchValue("");
  }, []);
  const expandedRowRender = () => (
    <ExpandedHubScreen recordData={recordData} allCpData={allCpData} />
  );
  const menuClicked = ({ key }) => {
    navigate(key);
  };
  const menu = (
    <Menu
      className="nav-dropdown-a"
      onClick={menuClicked}
      items={[
        {
          label: "Route Master",
          key: "/route-configuration",
          style: { fontSize: "14px" },
          route: "/route-configuration",
        },
        {
          label: "Sorting Guide",
          key: "/sorting-guide",
          style: { fontSize: "14px" },
          disabled: false,
          route: "/sorting-guide",
        },
        {
          label: "Pincode Mapping",
          key: "/pin-code-mapping",
          style: { fontSize: "14px" },
          route: "/pin-code-mapping",
        },
        {
          label: "Packeting Guide",
          key: "/packeting-guide",
          style: { fontSize: "14px" },
          route: "/packeting-guide",
        },
        {
          label: "CP Onboarding",
          key: "/cp-onboarding",
          style: { fontSize: "14px" },
          route: "/cp-onboarding",
        },
      ]}
    />
  );
  const columns = [
    {
      title: "Sl No",
      align: "center",
      with: 10,
      render: (a, b, idx) => serialNum(pageSize, pageNum, idx),
    },
    {
      title: "Hub Name",
      dataIndex: "premiseName",
      key: "premiseName",
      with: 15,
      render: (text) => (
        <div className="text">{camelCase(text) || "------"}</div>
      ),
    },
    {
      title: "PIN Code",
      dataIndex: "pincode",
      key: "pincode",
      with: 15,
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",

      with: 15,
      render: (text) => (
        <div className="text">{camelCase(text) || "------"}</div>
      ),
    },
    {
      title: "State Name",
      dataIndex: "state",
      key: "state",
      sorter: (a, b) => a?.state?.localeCompare(b?.state),
      with: 15,
      render: (text) => (
        <div className="text">{camelCase(text) || "------"}</div>
      ),
    },
    {
      title: "Zone",
      dataIndex: "zone",
      key: "zone",
      with: 15,
      render: (text) => (
        <div className="text">{text?.toUpperCase() || "------"}</div>
      ),
    },
    {
      title: "Lattitude",
      dataIndex: "latitude",
      key: "latitude",
      with: 15,
      render: (text) => <div className="text">{text}</div>,
    },
    {
      title: "Longitude",
      dataIndex: "longitude",
      key: "longitude",
      with: 15,
      render: (text) => <div className="text">{text}</div>,
    },
    {
      title: "Action",
      dataIndex: "premiseName",
      key: "premiseName",
      with: 15,
      render: (_,d) => (
        <>
        <div className="action-column">
        <Dropdown
          overlay={menu}
          overlayClassName="nav-dropdown-overlay"
          style={{ backgroundColor: "#d0e1fa" }}
        >
          <div className="text pointer">
            <Linked_Icon />
          </div>
        </Dropdown>
        <EditOutlined className="action-icon,active"  
        onClick={() => {
                setIsEditModalOpen((prev) => !prev);
                setEditData(d);
              }}/>
        </div>
      </>
      ),
    },
  ];
  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return (
      <div>
        <p>{originalElement} </p>
      </div>
    );
  };

  function handlePageChange(pagination) {
    setPageSize(pagination.pageSize);
    setCurrentPage(pagination?.current);
    setPageNum(pagination?.current);
    inputToSearchValue
      ? ""
      : dispatch(getAllHubOnboard(pagination?.current, pagination.pageSize));
  }

  const onSearch = async (e) => {
    const currValue = e.target.value;
    setInputSearchValue(currValue);
    setInputToSearchValue(currValue);
    let temp = [];
    if (currValue.length > 2) {
      setLoading(true);
      let response = await API.Api(
        `${HUB_LIST_SEARCH}${currValue}`
      ).getWithPremiseIdHeader();
      let res = response?.response;
      if (res?.status) {
        setLoading(false);
        res?.data?.data?.map((data) =>
          temp.push({
            ...data,
            key: data.premiseName,
          })
        );
        setAllHubData(temp);
        setTotalPage(res?.data?.count);
        setCurrentPage(0);
        setPageNum(0);
      } else {
        setLoading(false);
        message.error(res?.data?.message);
      }
    } else if (currValue.length < 3) {
      dispatch(getAllHubOnboard(currentPage, pageSize));
    }
  };
  const onExpandHandle = async (expanded, record) => {
    let keys = [];
    if (expanded) {
      setLoading(true);
      keys.push(record?.premiseName);
      setExpandedRowKeys([record?.premiseName]);

      let response = await API.Api(
        `${GET_EXPAND_HUB}${record?.premiseId}`
      ).getWithPremiseIdHeader();

      let res = response?.response;

      if (response?.status) {
        setLoading(false);
        setRecordData(res?.data);
        let response = await API.Api(
          `${GET_EXPAND_HUB_CP}${record?.premiseId}`
        ).getWithPremiseIdHeader();

        let resCp = response?.response;
        if (response?.status) {
          setAllCpData(resCp?.data);
          setLoading(false);
        }
      } else {
        setLoading(false);
        message.error(res?.data?.message);
      }
    } else {
      setExpandedRowKeys([]);
      setLoading(false);
    }
  };
  const handleViewModal = () => {
    setIsEditModalOpen(false);
    dispatch(getAllHubOnboard(currentPage, pageSize));
  };
  return (
    <Spin
      spinning={loading || fetching}
      indicator={
        <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
      }
    >
      {" "}
      <div className="template-container">
        <div className="d-flex space-between align-center mt-1-5 mb-1-5">
          <Input
            placeholder="Search by keywords (Hub Name / PIN Code / City /State /Zone )"
            bordered
            onChange={onSearch}
            style={{ borderRadius: "5px", height: "40px", width: "500px" }}
            suffix={<img src={search_icon} />}
            className="ml-1-5"
            value={inputSearchValue}
          />
          <div className="hub-onboard-buttons">
            <Button
              className="btn-style mr-1 download-btn"
              onClick={() =>
                window.open(
                  `${APIs.baseURL}/premise-service/v1/premise/hub/download`
                )
              }
            >
              <img src={download_pin_icon} className="mr-1" />
              DOWNLOAD
            </Button>
            <Button
              className="btn-style mr-1 download-btn"
              onClick={() =>
                window.open(
                  `${APIs.baseURL}/premise-service/v1/premise/hub/print`
                )
              }
            >
              <img src={print_logo} className="mr-1" />
              PRINT
            </Button>
            <Button
              className="btn-style save-btn mr-1"
              onClick={() => viewHubHandle()}
            >
              ADD HUB
            </Button>
          </div>
        </div>
        <Table
          loading={fetching || loading}
          columns={columns}
          dataSource={allHubData}
          pagination={{
            pageSize: pageSize,
            total: totalPage,
            itemRender: itemRender,
            pageSizeOptions: [5, 10, 20, 50, 100],
            current: currentPage == 0 ? currentPage + 1 : currentPage,
          }}
          onChange={handlePageChange}
          expandable={{
            expandedRowRender,
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <DownOutlined onClick={(e) => onExpand(record, e)} />
              ) : (
                <RightOutlined onClick={(e) => onExpand(record, e)} />
              ),
            expandRowByClick: false,
          }}
          expandedRowKeys={expandedRowKeys}
          onExpand={onExpandHandle}
          className="tat-table1 table-head-sticky"
        />
      </div>
      <Drawer
              title="Edit Hub Details"
              open={isEditModalOpen}
              onClose={handleViewModal}
              width={drawerWidth}
              className="custom-drawer"
            >
            <EditHubOnboard editData={editData} handleViewModal={handleViewModal}></EditHubOnboard> 
      </Drawer>
    </Spin>
  );
};
export default ViewAllHub;
