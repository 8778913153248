/* eslint-disable indent */
/* eslint-disable quotes */
/* eslint-disable no-unused-vars */
import React from "react";
import { Row, Col, Spin, Tooltip } from "antd";
import "./style.scss";
const ViewShipmentsRoute = ({ recordData,primaryMode }) => {
  const convertMinutesToHoursAndMinutes = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60); // Get the whole hours
    const minutes = totalMinutes % 60;          // Get the remaining minutes
    return `${hours} Hours : ${minutes} Minutes`;
  };
  const origin = recordData?.routes?.find((item) => item.position === 0);
  const originData = origin
    ? [
      { label: "Origin", value: origin.hubName },
      { label: "Primary mode of travel", value: primaryMode},
    ]
    : [];
  
  const transit = recordData?.routes?.filter(
    (item) => item?.position !== 0 && item?.position !==  recordData?.routes?.length - 1
  ); 
  const generateTransitData = (transit) => {
    const result = transit?.map((destination, index) => {      
      const labelPrefix = index > 0 ? `T${index} to T${index + 1}` : `Origin To T${index+1}`;
      return [
        { label: `Transit ${index+1}`, value: destination.hubName },
        { label: `${labelPrefix} Mode`,value:index == 0 ?`${origin?.travelBy}`:`${transit[index-1]&&transit[index-1]?.travelBy}`},
        { label: `${labelPrefix} Distance`, value: index == 0 ?`${origin?.distance} Km`:`${transit&&transit[index-1]?.distance} Km` },
        { label: `${labelPrefix} TAT (in hour)`, value:index == 0 ? convertMinutesToHoursAndMinutes(origin.transitTime): convertMinutesToHoursAndMinutes(transit[index-1]&&transit[index-1]?.transitTime) },
      ];
    });
  
    return result?.flat(); // Flatten the array for easier access
  };

  const destination = recordData?.routes?.find((item) => item.position ===  recordData?.routes?.length - 1);
  const destinationData=destination?[
    { label: "Destination Hub", value: destination.hubName },
    { label: transit.length>0?`T${transit.length} `+"to Destination Mode":"Origin to Destination Mode", value:transit.length !==0 ? transit[transit.length-1]?.travelBy:origin?.travelBy},
    { label: transit.length>0?`T${transit.length} `+"to Destination Distance":"Origin to Destination Distance", value:transit.length !==0 ? transit[transit.length-1]?.distance+" Km":origin?.distance+" Km" },
    { label: transit.length>0?`T${transit.length} `+"to Destination TAT (in hour)":"Origin to Destination TAT (in hour)", value: convertMinutesToHoursAndMinutes(transit.length !==0 ? transit[transit.length-1]?.transitTime:origin?.transitTime) }
  ]:[];
  
  return (
    <Spin spinning={recordData ? false : true}>
      <div className="shipmentRouteView expanded-hub-screen">
        <div className="expanded-hub-wrapper">
          <Row className="expanded-hub">
            {originData?.map((val) => {
              return (
                <>
                  <Col span={6}>
                    <Row className="expanded-hub-lable">{val?.label}</Row>
                    <Row
                      className="expanded-hub-value"
                      style={{ overflow: "hidden" }}
                    >
                      <Tooltip title={val?.value}>
                        {val?.value}
                      </Tooltip>
                    </Row>
                  </Col>
                </>
              );
            })}
          </Row>
          {/* <hr className="divider" /> */}
          <Row className="expanded-hub">
            {generateTransitData(transit)?.map((val) => {
              return (
                <>
                  <Col span={6}>
                    <Row className="expanded-hub-lable">{val?.label}</Row>
                    <Row
                      className="expanded-hub-value"
                      style={{ overflow: "hidden" }}
                    >
                      <Tooltip title={val?.value}>
                        {val?.value}
                      </Tooltip>
                    </Row>
                  </Col>
                </>
              );
            })}
          </Row>
          {/* <hr className="divider" /> */}
          <Row className="expanded-hub">
            {destinationData?.map((val) => {
              return (
                <>
                  <Col span={6}>
                    <Row className="expanded-hub-lable">{val?.label}</Row>
                    <Row
                      className="expanded-hub-value"
                      style={{ overflow: "hidden" }}
                    >
                      <Tooltip title={val?.value}>
                        {val?.value}
                      </Tooltip>
                    </Row>
                  </Col>
                </>
              );
            })}
          </Row>
        </div>
      </div>
    </Spin>
  );
};
export default ViewShipmentsRoute;
