/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { embedDashboard } from "@superset-ui/embedded-sdk";
import envUrls from "../../../config/env-urls";

const dashboardsID = {
  HUB_ADMIN: "173b67bc-ba65-4950-b0b3-b633fbde2868",
  RO_OPERATOR: "bf4f177c-0ba6-4073-a8f4-47be2a882ade"
};

const AllReports = () => {
  const REACT_APP_ENV = process.env.REACT_APP_ENV || "dev";
  const dashboardId = dashboardsID["HUB_ADMIN"];
  const guestTokenApi=envUrls[REACT_APP_ENV].API.reportGustTokenURL;
  const hubDetails=JSON.parse(localStorage.getItem("userId"));
  const hubRole=JSON.parse(localStorage.getItem("userinfo"));
  console.log("hubDetails",hubDetails,hubRole.roles[0],guestTokenApi);
  
  useEffect(() => {

    const initDashboard = async () => {
      try {
        await refreshTokens();
      } catch (error) {
        console.error("Error initializing dashboard:", error);
      }
    };
    initDashboard();
    const tokenInterval = setInterval(refreshTokens, 4 * 60 * 1000);
    return () => clearInterval(tokenInterval);
  }, []);
  const getRLS=(role)=>{
    let rls=[];
    if(hubRole.roles[0]=="ROLE_HUBADMIN"){
      rls=[
        {
          //Incoming LCR Bags 
          clause: `To_hub = '${hubDetails.premises.premiseName}'`,
          dataset: 823
        },
        {
          // Incoming Bags
          clause: `To_hub = '${hubDetails.premises.premiseName}'`,
          dataset: 828
        },
        {
          // Outscan LCR Bags 
          clause: `From_hub = '${hubDetails.premises.premiseName}'`,
          dataset: 825
        },
        {
          // Outgoing Bags
          clause: `From_hub = '${hubDetails.premises.premiseName}'`,
          dataset: 919
        },
        {
          // Hub Audit Entry Report 
          clause: `Audited_hub = '${hubDetails.premises.premiseName}'`,
          dataset: 839
        },
        {
          // Booking data vs Status data
          clause: `From_hub = '${hubDetails.premises.premiseName}'`,
          dataset: 840
        },
        {
          // Wrong sorting report
          clause: `From_hub = '${hubDetails.premises.premiseName}'`,
          dataset: 841
        },
        {
          // Booked vs Audited Report 
          clause: `Hub_name = '${hubDetails.premises.premiseName}'`,
          dataset: 833
        },
        {
           // Eway Report 
           clause: `Parent_hub = '${hubDetails.premises.premiseName}'`,
           dataset: 832
        },
        {
          // Shipment inscan vs outscan report
          clause: `Hub = '${hubDetails.premises.premiseName}'`,
          dataset: 910
       },
       {
        // Outscan LCR Shipments
        clause: `from_hub = '${hubDetails.premises.premiseName}'`,
        dataset: 915
      },
      {
        // Outscan Shipments
        clause: `From_Hub = '${hubDetails.premises.premiseName}'`,
        dataset: 916
      }
      
      ];
    }
    return rls;
  };
  const refreshTokens = async () => {
    try {
      const guestToken = await fetchGuestToken();
      embedDashboardWithToken(guestToken);
    } catch (error) {
      console.error("Error refreshing tokens:", error);
    }
  };
  const fetchGuestToken = async () => {
    try {
      const data = {
        username: envUrls[REACT_APP_ENV].auth.datazip_userName,
        password: envUrls[REACT_APP_ENV].auth.datazip_password,
        user: {
          username: envUrls[REACT_APP_ENV].auth.datazip_userName,
        },
        resources: [
          {
            type: "dashboard",
            id: dashboardId,
          },
        ],
        rls: getRLS(),
      };
  
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: guestTokenApi, // Ensure URL is correct
        headers: { 
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      };
      const response = await axios.request(config);
      return response?.data?.token; // Return data for further use
    } catch (error) {
      console.error("Error fetching guest token:", error);
      throw error; // Propagate error for handling
    }
  };
  const embedDashboardWithToken = (token) => {
    embedDashboard({
      id: dashboardId,
      supersetDomain: "https://app.datazip.delcaper.com",
      mountPoint: document.getElementById("superset-container"),
      fetchGuestToken: () => token,
      dashboardUiConfig: {
        hideTitle: true,
        hideTab: false,
        hideChartControls: false,
      },
    });
    let iframe = document.querySelector("#superset-container>iframe");
    iframe.style.height = "82vh";
    iframe.style.width = "94vw"; 
  };
  return (
    <><div style={{ paddingTop: "15px" }}>
      <div id="superset-container"></div>
    </div>
    </>
  );
};


export default AllReports;
