/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Button,
  Space,
  Table,
  message,
  Modal,
  Popconfirm,
  Row,
  Input,
  Select,
  Form,
  Drawer,
} from "antd";
import { EditOutlined, EyeOutlined, SyncOutlined } from "@ant-design/icons";
// import { useNavigate } from "react-router-dom";
import Map from "../Map";
import MasterModal from "../../../../components/Master/Modal";
import UploadDrawer from "../../PincodeMaster/uploadDrawer";
import AddNewRoute from "./AddNewRoute";
import upload_pin_icon from "../../../../assests/images/SVG/upload-pin-icon.svg";
import download_pin_icon from "../../../../assests/images/SVG/download-pin.svg";
import print_logo from "../../../../assests/images/SVG/master-print.svg";
import "../../PacketingGuide/style.scss";
import axios from "axios";
import { APIs } from "../../../../utils/worker";
import NormalAPI from "../../../../api";
import urls from "../../../../api/urls";
import nodeUrls from "../../../../api/nodeUrls";

import { capitalize, capitaliseWithHyphen } from "../../../../utils";
import constants from "../../../../constants/constants";
import { ReactComponent as Disable_icon } from "../../../../assests/images/SVG/disable.svg";
import { ReactComponent as Clock } from "../../../../assests/images/master/clock.svg";
import { ReactComponent as Edit_pen } from "../../../../assests/images/master/edit-pen.svg";

import EditRoute from "./EditRoute";
import ViewShipmentsRoute from "./viewRoute";

export let hubListWithMode = null;

export const setHubListWithMode = (value) => {
  hubListWithMode = value;
};

export const getHubListWithMode = () => hubListWithMode;

const {
  // CREATE_NEW_ROUTE,
  GET_ALL_ROUTE,
  GET_HUB_LISTS,
  GET_ALL_V_ROUTE,
  CREATE_V_ROUTE,
  DELETE_V_ROUTE,
  // UPDATE_NEW_ROUTE,
} = urls;

const { CREATE_NEW_ROUTE, UPDATE_NEW_ROUTE, SEARCH_ROUTES } = nodeUrls;

const { ROLES } = constants;
const { HO_ADMIN, HUB_ADMIN } = ROLES;

const RouteTable = ({ primaryMode }) => {
  //   const navigate = useNavigate();
  const [form] = Form.useForm();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [userToken, setUserToken] = useState("");
  const [scale, setScale] = useState(376);
  const [height, setHeight] = useState(220);
  const [width, setWidth] = useState("95%");
  const [tableDataSource, setDataSource] = useState([]);
  const [mapData, setMapData] = useState([]);
  const [mapDataParent, setMapDataParent] = useState([]);
  const [totalPageSize, setTotalPageSize] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [pageNum, setPageNum] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isMapModalVisible, setIsMapModalVisible] = useState(false);
  const [roles, setRoles] = useState();
  const [editRouteData, setEditRouteData] = useState(null);
  const [hubListDropdown, setHubListDropdown] = useState(null);
  // const [hubListWithMode, setHubListWithMode] = useState(null);

  const url = `/route-service/v1/upload/sevasetu-route/${primaryMode}`;
  const sampleUrl = "/route-service/v1/sample/" + primaryMode;

  const [originSearch, setOriginSearch] = useState(null);
  const [destinationSearch, setDestinationSearch] = useState(null);
  const [viewFlag, setViewFlag] = useState(true);
  let queryParams = `?pageNum=${pageNum}&pageSize=${pageSize}`;

  const getHubLists = async (params) => {
    setIsTableLoading(true);

    let response = await NormalAPI.Api(GET_HUB_LISTS).getWithHeader();
    if (response.status) {
      let temp = response?.response?.data;
      setHubListWithMode(temp);

      temp = temp?.map((val) => {
        return {
          label: capitalize(val?.premiseName),
          value: val?.premiseId,
        };
      });

      /* tempMode = tempMode?.map((val) => {
        return {
          label: val?.premiseId,
          value: val?.hubMode,
        };
      });*/
      setHubListDropdown(temp);
    } else {
      setHubListDropdown([]);
      // console.log("error");
    }
  };

  useEffect(() => {
    setRoles(JSON.parse(localStorage.getItem("userinfo")));
    getHubLists();
  }, []);

  const tableLoading = {
    spinning: isTableLoading,
    indicator: <SyncOutlined spin />,
  };
  const actionColumn =
    roles && roles?.roles?.includes(HO_ADMIN)
      ? {}
      : {
          title: "Action",
          key: "operation",
          fixed: "right",
          width: 100,
          render: (_, data) => (
            <Popconfirm
              title="Disable route"
              description="Are you sure to disable this route?"
              onConfirm={() => deleteRoute(data)}
              okText="Yes"
              cancelText="No"
              disabled={data?.active ? false : true}
            >
              <Disable_icon
                style={{ cursor: data?.active ? "pointer" : "not-allowed" }}
              />
            </Popconfirm>
          ),
        };

  function handleEditClickRoute(data) {
    setEditRouteData(data);
    setIsEditModalOpen(true);
    setViewFlag(true);
  }
  const columns = [
    {
      title: (
        <div>
          <div>Origin Hub</div>
          {/* <div>
            <input style={{ width: "150px" }} />
          </div> */}
        </div>
      ),
      dataIndex: "origin",
      key: "origin",
      width: 180,
      render: (text, record) => (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div> {capitaliseWithHyphen(text)}</div>

          <div>
            {roles && roles?.roles?.includes(HO_ADMIN) && (
              <EyeOutlined
                onClick={() => handleEditClickRoute(record)}
                style={{
                  cursor: "pointer",
                  marginLeft: "9px",
                  paddingTop: "15px",
                  fontSize: "24px",
                  color: "#2e77d0",
                }}
              />
            )}
          </div>
        </div>
      ),
      fixed: "left",
    },
    {
      title: (
        <div>
          <div>Destination Hub</div>
          {/* <div>
            <input style={{ width: "150px" }} />
          </div> */}
        </div>
      ),
      width: 150,

      dataIndex: "destination",
      key: "destination",
      render: (text) => <span>{capitaliseWithHyphen(text)}</span>,
      fixed: "left",
    },
    {
      width: 150,

      title: "Transit 1",
      dataIndex: "transit1",
      key: "transit1",
    },
    {
      width: 150,

      title: "Transit 2",
      dataIndex: "transit2",
      key: "transit2",
    },
    {
      width: 150,

      title: "Transit 3",
      dataIndex: "transit3",
      key: "transit3",
    },
    {
      width: 150,

      title: "Transit 4",
      dataIndex: "transit4",
      key: "transit4",
    },
    {
      width: 150,

      title: "Transit 5",
      dataIndex: "transit5",
      key: "transit5",
    },
    {
      width: 150,

      title: "Transit 6",
      dataIndex: "transit6",
      key: "transit6",
    },
    {
      width: 150,

      title: "Transit 7",
      dataIndex: "transit7",
      key: "transit7",
    },
    actionColumn,
  ];

  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem("userinfo"));
    setUserToken(userData.accessToken);
    getRouteList(queryParams);
  }, []);

  function modifyQuery(query) {
    // console.log(query, "query");

    let modifiedQuery = query.replace("pageSize=", "limit=");
    modifiedQuery = modifiedQuery.replace("pageNum=", "page=");

    modifiedQuery += `&mode=${primaryMode}`;

    return modifiedQuery;
  }

  const getRouteList = async (query) => {
    setIsTableLoading(true);
    try {
      let response = null;
      if (
        JSON.parse(localStorage.getItem("userinfo"))?.roles?.includes(HO_ADMIN)
      ) {
        if (query.includes("sourceHub") || query.includes("destinationHub")) {
          let replaceQuery = modifyQuery(query);
          response = await NormalAPI.Api(
            SEARCH_ROUTES + "/route" + replaceQuery
          ).getWithUserNPremiseHeader();
          // setTotalPageSize(response?.response?.data?.totalCounts);
        } else {
          response = await NormalAPI.Api(
            // GET_ALL_ROUTE + primaryMode + query
            SEARCH_ROUTES + "/route" + modifyQuery(query)
          ).getWithUserNPremiseHeader();
        }
      } else {
        response = await NormalAPI.Api(
          GET_ALL_V_ROUTE + primaryMode + modifyQuery(query)
        ).getWithUserNPremiseHeader();
      }

      let temp = [];

      setTotalPageSize(+response?.response?.data?.totalCounts);
      // if (originSearch || destinationSearch) {
      //   console.log("hello");
      //   setTotalPageSize(response?.response?.data.length);
      // }
      response?.response?.data?.data.map((item, index) => {
        if (item.route.length > 0) {
          temp.push({
            id: item.routeId,
            active: item?.active,
            originLat: item?.route[0].latitude,
            originLog: item?.route[0].longitude,
            premiseId: item?.route[0].premise,
            routes: item?.route,
            routeId: item.routeId,
            // transitTime: item?.route[index].transitTime,
            // travelBy: item?.route[index].travelBy,
            origin: capitalize(item.route[0].hubName),
            destination: capitalize(item.route[item.route.length - 1].hubName),
            destinationHubId: item.route[item.route.length - 1].premise,
            destinationHubTravelBy: item.route[item.route.length - 1].travelBy,

            transit1:
              item.route.length > 2
                ? capitalize(item.route[1].hubName)
                : "------",
            transit2:
              item.route.length > 3
                ? capitalize(item.route[2].hubName)
                : "------",
            transit3:
              item.route.length > 4
                ? capitalize(item.route[3].hubName)
                : "------",
            transit4:
              item.route.length > 5
                ? capitalize(item.route[4].hubName)
                : "------",
            transit5:
              item.route.length > 6
                ? capitalize(item.route[5].hubName)
                : "------",
            transit6:
              item.route.length > 7
                ? capitalize(item.route[6].hubName)
                : "------",
            transit7:
              item.route.length > 8
                ? capitalize(item.route[7].hubName)
                : "------",
          });
        }
      });
      setDataSource(temp);
      setMapData(response?.response?.data?.data);
      setMapDataParent(response?.response?.data?.data);
      setIsTableLoading(false);
    } catch (error) {
      message.error(error.response?.data?.message);
      setIsTableLoading(false);
    }
  };

  const deleteRoute = async (data) => {
    let response = await NormalAPI.Api(
      DELETE_V_ROUTE + data?.id
    ).deleteIdHeader();
    if (response?.status) {
      message.success("Disabled Successfully");
      getRouteList(queryParams);
    }
  };

  const handlePageChange = (pagination) => {
    setPageSize(pagination.pageSize);
    setPageNum(pagination.current);
    setCurrentPage(pagination.current);

    // getRouteList(queryParams);
    let queryParams = `?pageNum=${pagination.current - 1}&pageSize=${
      pagination.pageSize
    }`;

    if (originSearch) {
      queryParams += `&sourceHub=${originSearch}`;
    }

    if (destinationSearch) {
      queryParams += `&destinationHub=${destinationSearch}`;
    }

    getRouteList(queryParams);
  };
  //need this code
  // const handlePageChange = () => {
  //   window.scrollTo(0, 0);
  // };

  // const itemRender = (_, type, originalElement) => {
  //   if (type === "prev") {
  //     return <a>Previous</a>;
  //   }
  //   if (type === "next") {
  //     return <a>Next</a>;
  //   }
  //   return <span>{originalElement}</span>;
  // };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsEditModalOpen(false);
  };

  const handleEditRoute = async (values, formData, destinationTime) => {
    setLoading(true);

    let routeData = formData.map((val) => {
      return {
        distance: val?.distance,
        time: val?.final_time,
        premise_id: val?.premiseId || val?.hub,
        travel_by:
          val?.mode?.toUpperCase() === "BOTH"
            ? 2
            : val?.mode.toUpperCase() === "AIR"
            ? 0
            : 1, //val?.mode?.toUpperCase() == "AIR" ? 0 : 1,
      };
    });

    // Shift travel_by values by one index and add "dest" to the last item
    let shiftedRouteData = routeData?.map((val, index, arr) => {
      if (index === arr.length - 1) {
        return {
          ...val,
          time: val.final_time,
          travel_by:
            values?.destinationTransitMode?.toUpperCase() === "BOTH"
              ? 2
              : values?.destinationTransitMode?.toUpperCase() === "AIR"
              ? 0
              : 1, // values?.destinationTransitMode?.toUpperCase() == "AIR" ? 0 : 1,
        };
      } else {
        return {
          ...arr[index + 1], // Use the next item's travel_by value
          premise_id: val?.premise_id, // Preserve the current premise_id
          distance: val?.distance,
          time: val?.time,
        };
      }
    });
    let timeInMinutes = convertH2M(values?.destinationTransitTime);
    let payload;
    let updatedPayload;
    if (formData?.length == 0) {
      payload = {
        source: values?.originHub,
        destination: values?.destinationHub,
        source_distance: +values?.destinationTransitDistance,
        source_time: timeInMinutes,
        source_travel_by:
          values?.destinationTransitMode?.toUpperCase() === "BOTH"
            ? 2
            : values?.destinationTransitMode?.toUpperCase() === "AIR"
            ? 0
            : 1, // values?.destinationTransitMode?.toUpperCase() == "AIR" ? 0 : 1,
        destination_travel_by:
          values?.destinationTransitMode?.toUpperCase() === "BOTH"
            ? 2
            : values?.destinationTransitMode?.toUpperCase() === "AIR"
            ? 0
            : 1, // values?.destinationTransitMode?.toUpperCase() == "AIR" ? 0 : 1,
        transists: [],
      };
    } else {
      payload = {
        source: values?.originHub,
        destination: values?.destinationHub,
        source_distance: +values?.destinationTransitDistance,
        source_time: timeInMinutes,
        source_travel_by:
          values.transitMode0?.toUpperCase() === "BOTH"
            ? 2
            : values.transitMode0?.toUpperCase() === "AIR"
            ? 0
            : 1, // values?.destinationTransitMode?.toUpperCase() == "AIR" ? 0 : 1,
        destination_travel_by:
          values?.destinationTransitMode?.toUpperCase() === "BOTH"
            ? 2
            : values?.destinationTransitMode?.toUpperCase() === "AIR"
            ? 0
            : 1, // values?.destinationTransitMode?.toUpperCase() == "AIR" ? 0 : 1,
        transists: shiftedRouteData,
      };

      updatedPayload = {
        ...payload,
        source_distance: payload.transists[0].distance,
        source_time: payload.transists[0].time,
        transists: payload.transists.map((transit, index) => {
          if (index === payload.transists.length - 1) {
            return {
              ...transit,
              distance: payload.source_distance,
              time: payload.source_time,
            };
          }
          return {
            ...transit,
            distance: payload.transists[index + 1]?.distance,
            time: payload.transists[index + 1]?.time,
          };
        }),
      };
    }
    if (primaryMode === "AIR") {
      submitEditData(
        formData?.length == 0 ? payload : updatedPayload,
        values?.originHub,
        values?.destinationHub
      );
    } else {
      submitEditData(
        formData?.length == 0 ? payload : updatedPayload,
        values?.originHub,
        values?.destinationHub
      );
    }
  };

  function convertH2M(timeInHour) {
    const hoursMatch = timeInHour?.match(/(\d+)\s*hour/);
    const minutesMatch = timeInHour?.match(/(\d+)\s*minute/);
    const hours = hoursMatch ? parseInt(hoursMatch[1]) : 0;
    const minutes = minutesMatch ? parseInt(minutesMatch[1]) : 0;
    return hours * 60 + minutes;
  }

  const handleCreateRoute = async (values, transitForm, destinationTime) => {
    setLoading(true);
    let routeData = transitForm.map((val) => {
      return {
        distance: val?.distance,
        time: val?.final_time,
        premise_id: val?.premiseId || val?.hub,
        travel_by:
          val?.mode?.toUpperCase() === "BOTH"
            ? 2
            : val?.mode.toUpperCase() === "AIR"
            ? 0
            : 1, //val?.mode?.toUpperCase() == "AIR" ? 0 : 1,
      };
    });
    let shiftedRouteData = routeData?.map((val, index, arr) => {
      if (index === arr?.length - 1) {
        return {
          ...val,
          travel_by:
            values?.destinationTransitMode?.toUpperCase() === "BOTH"
              ? 2
              : values?.destinationTransitMode?.toUpperCase() === "AIR"
              ? 0
              : 1, // values?.destinationTransitMode?.toUpperCase() == "AIR" ? 0 : 1,
        };
      } else {
        const nextElement = arr[index + 1] || {};
        return {
          ...nextElement,
          premise_id: val?.premise_id,
        };
      }
    });
    let payload;
    let updatedPayload;
    let timeInMinutes = convertH2M(values?.destinationTransitTime);
    if (transitForm?.length == 0) {
      payload = {
        travel_by: primaryMode?.toUpperCase() == "AIR" ? 0 : 1,
        source: values?.originHub,
        // final_distance: +values?.destinationTransitDistance,
        source_distance: +values?.destinationTransitDistance,
        // ?.replace("km", "")
        // ?.trim()
        // ?.replace(",", ""),
        // final_time: timeInMinutes,
        source_time: timeInMinutes,
        destination: values?.destinationHub,
        source_travel_by:
          values?.destinationTransitMode?.toUpperCase() === "BOTH"
            ? 2
            : values?.destinationTransitMode?.toUpperCase() === "AIR"
            ? 0
            : 1, // values?.destinationTransitMode?.toUpperCase() == "AIR" ? 0 : 1,
        destination_travel_by:
          values?.destinationTransitMode?.toUpperCase() === "BOTH"
            ? 2
            : values?.destinationTransitMode?.toUpperCase() === "AIR"
            ? 0
            : 1, // values?.destinationTransitMode?.toUpperCase() == "AIR" ? 0 : 1,
        transists: [],
      };
    } else {
      payload = {
        source: values?.originHub,
        destination: values?.destinationHub,
        // final_distance: +values?.destinationTransitDistance,
        source_distance: values?.destinationTransitDistance,
        // ?.replace("km", "")
        // ?.trim()
        // ?.replace(",", ""),
        // final_time: timeInMinutes,
        source_time: timeInMinutes,
        // travel_by: formData[0]?.mode?.toUpperCase() == "AIR" ? 0 : 1,
        travel_by: primaryMode?.toUpperCase() == "AIR" ? 0 : 1,
        source_travel_by:
          values.transitMode0?.toUpperCase() == "BOTH"
            ? 2
            : values.transitMode0?.toUpperCase() == "AIR"
            ? 0
            : 1,
        destination_travel_by:
          values.destinationTransitMode?.toUpperCase() == "BOTH"
            ? 2
            : values.destinationTransitMode?.toUpperCase() == "AIR"
            ? 0
            : 1,
        transists: shiftedRouteData,
      };
      updatedPayload = {
        ...payload,
        source_distance: payload.transists[0].distance,
        source_time: payload.transists[0].time,
        transists: payload.transists.map((transit, index) => {
          if (index === payload.transists.length - 1) {
            return {
              ...transit,
              distance: payload.source_distance,
              time: payload.source_time,
            };
          }
          return {
            ...transit,
            distance: payload.transists[index + 1]?.distance,
            time: payload.transists[index + 1]?.time,
          };
        }),
      };
    }

    //this below logic for temprory soltion
    // submitData(payload);
    submitData(transitForm?.length == 0 ? payload : updatedPayload);
  };

  const submitEditData = async (payload) => {
    let response = null;
    if (roles && roles?.roles?.includes(HO_ADMIN)) {
      response = await NormalAPI.Api(
        UPDATE_NEW_ROUTE + editRouteData?.routeId
      ).putIdHeader(payload);
    } else {
      response = await NormalAPI.Api(
        UPDATE_NEW_ROUTE + editRouteData?.routeId
      ).putIdHeader(payload);
    }
    if (response.status) {
      message.success(response?.data?.message || "Route updated Successfully");
      setLoading(false);
      setIsModalOpen(false);
      setIsEditModalOpen(false);
      setCurrentPage(1);
      getRouteList(queryParams);
    } else {
      message.error(response.response.data.message);
      setLoading(false);
    }
  };

  const submitData = async (payload, originHub, destinationHub) => {
    let response = null;
    if (roles && roles?.roles?.includes(HO_ADMIN)) {
      response = await NormalAPI.Api(CREATE_NEW_ROUTE).postWithPrmiseHeader(
        payload
      );
    } else {
      response = await NormalAPI.Api(CREATE_V_ROUTE).postIdHeader(payload);
    }
    if (response.status) {
      message.success(response?.data?.message || "Route Created Successfully");
      setLoading(false);
      setIsModalOpen(false);
      setCurrentPage(1);
      getRouteList(queryParams);
    } else {
      message.error(response.response.data.message);
      setLoading(false);
    }
  };

  const handleCallBack = () => {
    getRouteList(queryParams);
  };

  const goFull = (id) => {
    const target = document.getElementById("fullscreen-target-" + id);
    target.addEventListener("click", (e) => {
      setScale(550);
      setHeight(365);
      setWidth("calc(180% - 120px)");
      setIsMapModalVisible(true);
      // e.currentTarget?.requestFullscreen();
    });
  };
  const goClose = () => {
    document.webkitExitFullscreen();
    setScale(376);
    setHeight(220);
    setWidth("95%");
    setIsMapModalVisible(false);
  };

  const downloadAllRoute = () => {
    //old URL
    // window.open(APIs.baseURL + "/route-service/v1/export/" + primaryMode);
    const queryParams=`travelBy=${primaryMode}`+`&origin=${originSearch}`+`&destination=${destinationSearch}`;
    try {
      window.open(APIs.baseURL + "/route-service/v1/routes/download?" + queryParams);
    } catch (error) {
      alert.message(error);
    }
  };

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return (
      <div>
        <p>{originalElement} </p>
      </div>
    );
  };

  const handleClose = () => {
    setDrawerOpen(false);
  };

  function handleOriginSearch(params) {
    setOriginSearch(params);
    setCurrentPage(1);
    let query;
    query="?pageNum=0&pageSize=100";
    if (destinationSearch) {
      query += `&destinationHub=${destinationSearch}`;
    }
    query += `&sourceHub=${params}`;
    getRouteList(query);
  }

  function handleDestSearch(params) {
    setDestinationSearch(params);
    setCurrentPage(1);
    let query="?pageNum=0&pageSize=100";
    if (originSearch) {
      query += `&sourceHub=${originSearch}`;
    }
    query += `&destinationHub=${params}`;
    getRouteList(query);
  }

  const handleOriginClear = () => {
    setOriginSearch(null);
    if (destinationSearch) {
      queryParams += `&destinationHub=${destinationSearch}`;
    }
    getRouteList(queryParams);
  };

  const handleDestinationClear = () => {
    setDestinationSearch(null);
    if (originSearch) {
      queryParams += `&sourceHub=${originSearch}`;
    }
    getRouteList(queryParams);
  };
  const [drawerWidth, setDrawerWidth] = useState(1100); // Default width
  // Function to update drawer width based on window size
  const updateDrawerWidth = () => {
    const windowWidth = window.innerWidth;
    if (windowWidth < 768) {
      setDrawerWidth(windowWidth * 0.9); // 90% of window width for small screens
    } else if (windowWidth < 1200) {
      setDrawerWidth(windowWidth * 0.7); // 70% of window width for medium screens
    } else {
      setDrawerWidth(1100); // Default for large screens
    }
  };

  useEffect(() => {
    updateDrawerWidth(); // Set width initially
    window.addEventListener("resize", updateDrawerWidth); // Update on window resize

    return () => {
      window.removeEventListener("resize", updateDrawerWidth); // Cleanup listener
    };
  }, []);
  return (
    <div className="master-main-container" style={{ paddingBottom: "1rem" }}>
      {roles && roles?.roles?.includes(HO_ADMIN) ? (
        <Map
          scale={scale}
          height={height}
          width={width}
          goClose={goClose}
          mapData={mapData}
          mode={primaryMode.toLowerCase()}
          goFull={goFull}
          showLables={false}
        />
      ) : (
        ""
      )}
      <div
        className={
          roles && roles?.roles?.includes(HO_ADMIN)
            ? "master-main-container m-2"
            : ""
        }
        style={{ marginTop: "1rem" }}
      >
        <div className="p-1 space-between" style={{ flexWrap: "wrap" }}>
          <div className="">
            <Form
              form={form}
              className="d-flex global-form"
              style={{ alignItems: "center" }}
            >
              <div className="global-form-row d-flex">
                <div style={{ marginRight: "15px" }}>
                  <span>Origin</span>
                  <>
                    <Form.Item
                      name={"originHub"}
                      // initialValue={primaryMode}
                      // initialValue={primaryMode === "SURFACE" ? "SURFACE" : null}
                    >
                      <Select
                        showSearch
                        style={{
                          width: "275px",
                        }}
                        allowClear
                        onClear={handleOriginClear}
                        onSearch={() => {}}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        placeholder="Search origin"
                        // disabled={primaryMode === "AIR" ? false : true}
                        options={hubListDropdown}
                        onSelect={(data) =>
                          handleOriginSearch(data, "originHub")
                        }
                      />
                    </Form.Item>
                  </>
                </div>
                <div>
                  <span>Destination</span>
                  <>
                    <Form.Item
                      name={"destinationHub"}
                      // initialValue={primaryMode}
                      style={{
                        width: "275px",
                      }}
                    >
                      <Select
                        placeholder="Search destination"
                        showSearch
                        allowClear
                        onSearch={() => {}}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        onClear={handleDestinationClear}
                        // disabled={primaryMode === "AIR" ? false : true}
                        options={hubListDropdown}
                        onSelect={(data) =>
                          handleDestSearch(data, "destinationHub")
                        }
                      />
                    </Form.Item>
                  </>
                </div>
              </div>

              <div>
                <Button
                  className="cancel-btn ml-1"
                  style={{ marginTop: "5px" }}
                  onClick={() => {
                    setOriginSearch(null);
                    setDestinationSearch(null);
                    form.resetFields();
                    getRouteList(queryParams);
                  }}
                  disabled={!originSearch && !destinationSearch}
                >
                  CLEAR
                </Button>
              </div>
            </Form>
          </div>

          <div className="d-flex">
            <Button
              className="cancel-btn"
              onClick={() => setDrawerOpen(true)}
            >
              <img src={upload_pin_icon} alt="upload button" />
              &nbsp;UPLOAD
            </Button>
            <Button
              className="cancel-btn ml-1"
              onClick={downloadAllRoute}
              // disabled
            >
              <img src={download_pin_icon} alt="download logo" />
              &nbsp;DOWNLOAD
            </Button>
            <Button
              className="save-btn ml-1"
              onClick={() => setIsModalOpen(true)}
              // onClick={() => setIsEditModalOpen(true)}
            >
              CREATE NEW ROUTE
            </Button>
          </div>
        </div>
        <Table
          // className="table-head-sticky"
          columns={columns}
          dataSource={tableDataSource}
          loading={tableLoading}
          onRow={(record) => ({
            style: {
              background: record?.active === false ? "#E2E2E2" : "white",
            },
          })}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: totalPageSize,
            itemRender: itemRender,
          }}
          onChange={handlePageChange}
          scroll={{
            x: 1700,
            y: 500,
          }}
        />
      </div>
      <MasterModal
        title="Create New Route"
        content={
          <AddNewRoute
            handleCreateRoute={handleCreateRoute}
            handleCloseModal={handleCloseModal}
            primaryMode={primaryMode}
          />
        }
        visible={isModalOpen}
        width={1100}
        handleCloseModal={handleCloseModal}
        handleCreateRoute={handleCreateRoute}
        loading={loading}
      />
      {/* <MasterModal
        title="Edit Route"
        content={
          <EditRoute
            editRouteData={editRouteData}
            handleEditRoute={handleEditRoute}
            handleCloseModal={handleCloseModal}
            primaryMode={primaryMode}
          />
        }
        visible={isEditModalOpen}
        width={1100}
        handleCloseModal={handleCloseModal}
        handleEditRoute={handleEditRoute}
        loading={loading}
      /> */}
      {/* //////////////ADD Drawer///////////////// */}
      <Drawer
        title={viewFlag ? "View Route" : "Edit Route"}
        visible={isEditModalOpen}
        onClose={handleCloseModal}
        width={drawerWidth}
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            type="primary"
            className="save-btn"
            onClick={() => {
              setViewFlag((prev) => !prev), setEditRouteData(editRouteData);
            }}
          >
            {viewFlag ? (
              <>
                <EditOutlined
                  style={{ fontSize: 16, textDecoration: "none" }}
                />{" "}
                Edit{" "}
              </>
            ) : (
              <>
                <EyeOutlined style={{ fontSize: 16 }} /> View
              </>
            )}
          </Button>
        </div>
        {/* */}
        {viewFlag ? (
          <>
            {" "}
            <ViewShipmentsRoute
              recordData={editRouteData}
              primaryMode={primaryMode}
            />{" "}
          </>
        ) : (
          <>
            <EditRoute
              editRouteData={editRouteData}
              handleEditRoute={handleEditRoute}
              handleCloseModal={handleCloseModal}
              primaryMode={primaryMode}
            />{" "}
          </>
        )}
      </Drawer>
      {/* //////////////////////////////// */}
      <UploadDrawer
        title="Upload Route Map"
        open={drawerOpen}
        onClose={handleClose}
        url={url}
        sampleUrl={sampleUrl}
        type={primaryMode === "AIR" ? "Route Air" : "Route Surface"}
        // docType={docType}
        handleCallBack={handleCallBack}
      />
      <Modal
        visible={isMapModalVisible}
        onCancel={goClose}
        footer={false}
        height={1000}
        width={1500}
        destroyOnClose
      >
        <Map
          scale={scale}
          height={height}
          width={width}
          goClose={goClose}
          mapData={mapData}
          mode={primaryMode.toLowerCase()}
          goFull={goFull}
          showLables={true}
        />
      </Modal>
    </div>
  );
};

export default RouteTable;
