/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React, { useEffect, useState, useRef } from "react";
import { Input, Table, message, Select, DatePicker, Button } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

import {
  serialNum,
  capitalize,
  checkNumbervalue,
  checkSpace,
  checkSpecialCharacter,
  commonSorterForTable,
  isValidAwb,
} from "../../../utils";
import urls from "../../../api/urls";
import API from "../../../api";

import search_icon from "../../../assests/images/search-icon.svg";
import checkIcon from "../../../assests/images/SVG/check.svg";
import OrangeCheckIcon from "../../../assests/images/SVG/orange-check.svg";

import blue_bag_plus from "../../../assests/images/middleMile/blue-bag.svg";
import green_bag from "../../../assests/images/middleMile/green-bag.svg";
import white_bag from "../../../assests/images/middleMile/white-bag.svg";
import yellow_bag from "../../../assests/images/middleMile/yellow-bag.svg";
import Smcs_Loader from "../../../assests/images/loader-new.gif";
import "./style.scss";
import Heading from "../../../components/Heading";
import BagContentModal from "./bagContentModal";
import ScannedShipment from "../../../components/ScannedShipment";
import moment from "moment";
import { damageBagModal } from "../Bagging/damageBagModal";

const { GET_LCR_IN_SCAN_LIST_NEW, UPDATE_ALL_BAGS_NEW1, NEW_INSCAN } = urls;
const { RangePicker } = DatePicker;

const LCRScanList = () => {
  const inputRef = useRef(null);

  const [tableData, setTableData] = useState([]);
  // const [tableData2, setTableData2] = useState([
  //   {
  //     lcrNum: 4939449627,
  //     enable: true,
  //     fromDate: "2024-08-09 09:52:02",
  //     fromHub: "MAHADEVAPURA HUB",
  //     toHub: "BENGALURU HSR HUB",
  //     destinationHub: "MAHADEVAPURA HUB",
  //     fromPremiseId: 212,
  //     toPremiseId: 53,
  //     travelBy: "Surface",
  //     vehicleNum: "KA56YU5667",
  //     driverName: "Ganesh",
  //     shipmentNum: 1,
  //     scanCount: 0,
  //     bagNum: null,
  //     status: "OUTSCAN",
  //     barcode: 23469999999,
  //     isBag: true,
  //     isShipment: null,
  //     isChildShipment: null,
  //     barcodeStatus: "DAMAGED",
  //     destroyPremiseId: 53,
  //     isScanned: true,
  //     color: "#2E7FE1",
  //     tripId: "BENGALURU-212-1414",
  //   },
  //   {
  //     lcrNum: 8919363838,
  //     enable: true,
  //     fromDate: "2024-08-14 10:38:04",
  //     fromHub: "BENGALURU HEBBAL HUB",
  //     toHub: "BENGALURU HSR HUB",
  //     destinationHub: "BENGALURU HSR HUB",
  //     fromPremiseId: 38,
  //     toPremiseId: 53,
  //     travelBy: "Surface",
  //     vehicleNum: "KA03AG8159",
  //     driverName: "Soham Kumari",
  //     shipmentNum: 1,
  //     scanCount: 0,
  //     bagNum: null,
  //     status: "OUTSCAN",
  //     barcode: 24038200126875,
  //     isBag: null,
  //     isShipment: true,
  //     isChildShipment: null,
  //     barcodeStatus: "HUB-OUTSCAN",
  //     destroyPremiseId: null,
  //     isScanned: false,
  //     color: null,
  //     tripId: "BENGALURU-38-1476",
  //   },
  //   {
  //     lcrNum: 8752547874,
  //     enable: true,
  //     fromDate: "2024-08-14 06:33:22",
  //     fromHub: "BENGALURU HEBBAL HUB",
  //     toHub: "BENGALURU HSR HUB",
  //     destinationHub: "MAHADEVAPURA HUB",
  //     fromPremiseId: 38,
  //     toPremiseId: 53,
  //     travelBy: "Surface",
  //     vehicleNum: "KA56FG1290",
  //     driverName: "Harish",
  //     shipmentNum: 2,
  //     scanCount: 0,
  //     bagNum: null,
  //     status: "COMPLETED",
  //     barcode: 24909090911,
  //     isBag: true,
  //     isShipment: null,
  //     isChildShipment: null,
  //     barcodeStatus: "INSCANNED_AT_TRANSIT",
  //     destroyPremiseId: 53,
  //     isScanned: true,
  //     color: "#2E7FE1",
  //     tripId: "BENGALURU-212-1460",
  //   },
  //   {
  //     lcrNum: 1051324860,
  //     enable: true,
  //     fromDate: "2024-08-13 12:43:48",
  //     fromHub: "MAHADEVAPURA HUB",
  //     toHub: "BENGALURU HSR HUB",
  //     destinationHub: "BENGALURU HSR HUB",
  //     fromPremiseId: 212,
  //     toPremiseId: 53,
  //     travelBy: "Surface",
  //     vehicleNum: "KA56FG1290",
  //     driverName: "Harish",
  //     shipmentNum: 1,
  //     scanCount: 0,
  //     bagNum: null,
  //     status: "OUTSCAN",
  //     barcode: 23459999999,
  //     isBag: true,
  //     isShipment: null,
  //     isChildShipment: null,
  //     barcodeStatus: "HUB-OUTSCAN",
  //     destroyPremiseId: null,
  //     isScanned: false,
  //     color: "#fff",
  //     tripId: "BENGALURU-212-1460",
  //   },
  //   {
  //     lcrNum: 8778601051,
  //     enable: true,
  //     fromDate: "2024-08-13 07:35:48",
  //     fromHub: "BENGALURU HEBBAL HUB",
  //     toHub: "BENGALURU HSR HUB",
  //     destinationHub: "BENGALURU HSR HUB",
  //     fromPremiseId: 38,
  //     toPremiseId: 53,
  //     travelBy: "Surface",
  //     vehicleNum: "KA03AF4976",
  //     driverName: "Sundar Singh",
  //     shipmentNum: 1,
  //     scanCount: 0,
  //     bagNum: null,
  //     status: "COMPLETED",
  //     barcode: 24353535355,
  //     isBag: true,
  //     isShipment: null,
  //     isChildShipment: null,
  //     barcodeStatus: "HUB-IN-SCANNED",
  //     destroyPremiseId: null,
  //     isScanned: false,
  //     color: "#2E7FE1",
  //     tripId: "BENGALURU-38-1447",
  //   },
  // ]);

  const [loading, setLoading] = useState(false);
  const [dataSource, setdataSource] = useState(tableData);
  const [inputSearchValue, setInputSearchValue] = useState("");
  const [totalScanned, setTotalScanned] = useState(0);
  const [totalShipments, setTotalShipments] = useState(0);
  const [totalBags, setTotalBags] = useState(0);
  const [totalBagsScanned, setTotalBagsScanned] = useState(0);

  const [pageSize, setPageSize] = useState(20);
  const [pageNum, setPageNum] = useState(0);
  const [showBagModal, setShowBagModal] = useState(false);
  const [currentBarCode, setCurrentBarCode] = useState(null);
  //const [showDamageBagModal, setShowDamageBagModal] = useState(false);
  const [filteredInfo, setFilteredInfo] = useState({});

  const HUB_INSCANNED_LABEL = "HUB-IN-SCANNED";
  const white = "#fff";
  const blur = "#2E7FE1";
  const green = "#70C36E";
  const yello = "#D2DD3F";

  const colorDictionary = {
    "#fff": white_bag,
    "#2E7FE1": blue_bag_plus,
    "#70C36E": green_bag,
    "#D2DD3F": yellow_bag,
  };

  const [sortedInfo, setSortedInfo] = useState({});

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
    setFilteredInfo(filters);
  };

  useEffect(() => {
    let todaysDate = moment(new Date(), "ddd MMM DD YYYY HH:mm:ss").format(
      "DD/MM/YYYY"
    );
    getLcrInscanList(todaysDate, todaysDate);
  }, []);

  const closeModal = () => {
    setShowBagModal(false);
  };

  const getLcrInscanList = async (start, end) => {
    setLoading(true);

    const StartDate = moment(start, "DD/MM/YYYY").format("DD-MM-YYYY");
    const endDate = moment(end, "DD/MM/YYYY").format("DD-MM-YYYY");
    let response;

    if (start) {
      response = await API.Api(
        GET_LCR_IN_SCAN_LIST_NEW +
          "?fromDate=" +
          StartDate +
          "&toDate=" +
          endDate
      ).getWithPremiseIdHeader();
    } else {
      response = await API.Api(
        GET_LCR_IN_SCAN_LIST_NEW
      ).getWithPremiseIdHeader();
    }

    if (response?.status) {
      setLoading(false);
      let data = response?.response?.data?.data;
      setTableData(data);
      setdataSource(data);
      calculateTotals(data);
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message || "Network error");
    }
  };

  function handleDateChange(params, p2) {
    const startDate = params[0];
    const endDate = params[1];
    const diffInDays = endDate.diff(startDate, "days");
    if (diffInDays > 7) {
      message.error("You can only select a date range of 7 days or less!");
      return;
    }
    getLcrInscanList(p2[0], p2[1]);
  }

  const filterData = (param) => {
    const unique = [
      ...new Map(tableData?.map((item) => [item[param], item])).values(),
    ];

    const data = unique?.map((val) => {
      return {
        text: param == "vehicleNum" ? val[param] : capitalize(val[param]),
        value: val[param],
      };
    });
    return data;
  };

  function handleIdClick(val, barcode) {
    if (
      val?.barcodeStatus !== "HUB-OUTSCAN" &&
      val?.destroyPremiseId === null &&
      val?.isBag
    ) {
      setCurrentBarCode(barcode);
      setShowBagModal(true);
    }
  }

  const checkStatus = (val) => {
    return (
      val?.barcodeStatus !== "HUB-OUTSCAN" &&
      val?.destroyPremiseId === null &&
      val?.isBag
    );
  };

  const columns = [
    {
      title: "Sl No",
      align: "center",
      width: 10,
      render: (a, b, idx) => serialNum(pageSize, pageNum, idx),
    },
    {
      title: "LCR ID",
      dataIndex: "lcrNum",
      key: "lcrNum",
      width: 18,

      filters: filterData("lcrNum"),
      filteredValue: filteredInfo?.lcrNum || null,
      onFilter: (value, record) => record?.lcrNum === value,
      render: (text, record) => {
        return (
          <>
            <div
              // onClick={() => navigate("/lcr-packets-scan/" + text)}
              className="align-center"
              style={{ fontWeight: "500" }}
            >
              {text}
            </div>
          </>
        );
      },
    },
    {
      title: "Vehicle LCR Id",
      dataIndex: "vehicleLcrId",
      key: "vehicleLcrId",
      width: 18,
    },
    {
      title: "Trip ID",
      dataIndex: "tripId",
      key: "tripId",
      width: 19,

      render: (text) => <div className="align-center">{text}</div>,
    },
    {
      title: "Bag ID/ Individul shipment",
      dataIndex: "barcode",
      key: "barcode",
      width: 25,

      render: (text, val) => {
        return (
          <div
            // onClick={() => navigate("/lcr-packets-scan/" + text)}
            className="align-center"
          >
            <span
              className={checkStatus(val) ? "mr-1 pointer" : "mr-1"}
              style={{
                color: checkStatus(val) ? "#4394d3" : "black",
              }}
              onClick={() => handleIdClick(val, text)}
            >
              {text}
            </span>
            {val?.isBag ? (
              <img
                src={colorDictionary[val?.color]}
                style={{ width: "10px" }}
              />
            ) : null}
            {val?.isScanned && val?.isShipment ? (
              <img src={checkIcon} className="ml-1" />
            ) : val?.isScanned && val?.isBag ? (
              <img
                src={
                  val?.shipmentNum == val?.scanCount
                    ? checkIcon
                    : OrangeCheckIcon
                }
                className="ml-1"
              />
            ) : null}
          </div>
        );
      },
    },

    {
      title: "Date",
      dataIndex: "fromDate",
      key: "fromDate",
      width: 15,

      sortOrder: sortedInfo.columnKey === "fromDate" ? sortedInfo.order : null,
      sorter: (a, b) => commonSorterForTable(a, b, "fromDate"),
      ellipsis: true,
      render: (text) => (
        <div style={{ width: "100px" }}>
          {text?.split(" ")[0]?.split("-")?.reverse()?.join("-")}
        </div>
      ),
    },
    {
      title: "Mode",
      width: 15,

      dataIndex: "travelBy",
      key: "travelBy",
      sortOrder: sortedInfo.columnKey === "travelBy" ? sortedInfo.order : null,
      sorter: (a, b) => commonSorterForTable(a, b, "travelBy"),
      ellipsis: true,
      render: (text) => <div>{capitalize(text)}</div>,
    },
    {
      title: "No of shipments",
      width: 16,

      dataIndex: "shipmentNum",
      key: "shipmentNum",
      render: (text) => <div>{text === 0 ? "------" : text}</div>,
    },

    {
      title: "Scanned shipments",
      width: 15,

      dataIndex: "scanCount",
      key: "scanCount",
      render: (text) => <div>{text ? text : "---"}</div>,
    },
    // {
    //   title: "Serivce Type",
    //   dataIndex: "service",
    //   render: (text) => <div>{text ? text : "---"}</div>,
    // },
    {
      title: "From Hub",
      width: 15,

      dataIndex: "fromHub",
      key: "fromHub",
      sortOrder: sortedInfo.columnKey === "fromHub" ? sortedInfo.order : null,
      sorter: (a, b) => commonSorterForTable(a, b, "fromHub"),
      ellipsis: true,
      filteredValue: filteredInfo.fromHub || null,
      filters: filterData("fromHub"),
      onFilter: (value, record) => record?.fromHub?.startsWith(value),
      filterSearch: false,
      render: (text) => <div>{capitalize(text)}</div>,
    },

    {
      title: "Destination Hub",
      width: 20,

      dataIndex: "destinationHub",
      key: "destinationHub",
      filterSearch: false,
      sortOrder:
        sortedInfo.columnKey === "destinationHub" ? sortedInfo.order : null,
      sorter: (a, b) => commonSorterForTable(a, b, "destinationHub"),
      ellipsis: true,
      filteredValue: filteredInfo.destinationHub || null,
      filters: filterData("destinationHub"),
      onFilter: (value, record) => record?.destinationHub?.startsWith(value),
      render: (text) => <div>{capitalize(text) || "-------"}</div>,
    },
    {
      title: "Vehicle/Flight No",
      dataIndex: "vehicleNum",
      width: 22,

      key: "vehicleNum",
      sortOrder:
        sortedInfo.columnKey === "vehicleNum" ? sortedInfo.order : null,
      sorter: (a, b) => commonSorterForTable(a, b, "vehicleNum"),
      ellipsis: true,
      filters: filterData("vehicleNum"),
      filterSearch: false,
      filteredValue: filteredInfo?.vehicleNum || null,
      onFilter: (value, record) => record?.vehicleNum?.startsWith(value),
    },
  ];

  const filterArray = (data, currValue) => {
    return data?.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(currValue.toLowerCase())
      )
    );
  };

  const onSearch = (e) => {
    const currValue = e.target.value;
    setInputSearchValue(currValue);

    const filteredData =
      inputSearchValue.length > 0 || inputSearchValue.length == 0
        ? filterArray(dataSource, currValue)
        : filterArray(dataSource, currValue);
    setTableData(filteredData);
  };

  //--Barcode Scanner--//
  let barcode = "";
  let interval;
  const handleKeyDown = (event) => {
    if (interval) clearInterval(interval);
    if (event.code == "Enter") {
      if (barcode) handleScan(barcode);
      barcode = "";
      return;
    }
    if (event.key != "Shift") barcode += event.key;
    interval = setInterval(() => (barcode = ""), 20);
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    // cleanup this component
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [tableData]);

  const handleScan = async (id) => {
    const resultNumber = id?.replace(/[a-zA-Z]/g, "");
    const validateDocNum = isValidAwb(resultNumber);
    if (validateDocNum) {
      setLoading(true);
      //UPDATE_ALL_BAGS_NEW1 + "/status/" + HUB_INSCANNED_LABEL
      //awbNumbers: [resultNumber],

      let res = await API.Api(NEW_INSCAN).postIdHeader({
        barcodes: resultNumber,
      });

      let response = res?.response?.data;
      if (res?.status) {
        setLoading(false);
        if (response) {
          message.success(response);
          let scanData = [...tableData];
          const getCurrentIndex = scanData?.findIndex(
            (item) => item?.barcode == +resultNumber
          );
          const barcodeIsBag = scanData[getCurrentIndex]?.isBag;
          if (barcodeIsBag) {
            if (!getCurrentIndex?.isScanned) {
              setTotalScanned(totalScanned + 1);
            }
            scanData[getCurrentIndex].barcodeStatus = "HUB-IN-SCANNED";
            scanData[getCurrentIndex].isScanned = true;
            scanData[getCurrentIndex].scanCount =
              (scanData[getCurrentIndex]?.scanCount || 0) + 1;
          } else {
            if (!getCurrentIndex?.isScanned) {
              setTotalScanned(totalScanned + 1);
            }
            scanData[getCurrentIndex].barcodeStatus = "HUB-IN-SCANNED";
            scanData[getCurrentIndex].isScanned = true;
            scanData[getCurrentIndex].scanCount =
              (scanData[getCurrentIndex]?.scanCount || 0) + 1;
          }
          setTableData(scanData);
        }
      } else {
        message.error(response?.message || "Network error");
        setLoading(false);
      }
    } else {
      message.error("Please enter a valid AWB Number.");
    }
  };
  const calculateTotals = (filteredData) => {
    let totalShipsScanned = 0;
    let totalShips = 0;

    let totalBagList = filteredData?.filter((item) => item?.isBag);
    let totalBags = totalBagList?.length;
    let totalBagsScanned = totalBagList?.filter(
      (item) =>
        item?.isBag &&
        (item?.barcodeStatus === "HUB-IN-SCANNED" ||
          item?.barcodeStatus === "INSCANNED_AT_TRANSIT")
    )?.length;

    filteredData.forEach((item) => {
      if (item?.scanCount !== null) {
        totalShipsScanned += item?.scanCount;
      }
      if (item?.shipmentNum !== null && !isNaN(item?.shipmentNum)) {
        totalShips += item?.shipmentNum;
      }
    });
    setTotalShipments(totalShips);
    setTotalScanned(totalShipsScanned);
    setTotalBags(totalBags);
    setTotalBagsScanned(totalBagsScanned);
  };

  function handlePageChange(pagination, filters, sorter, extra) {
    if (extra.action === "filter") {
      const filteredData = extra.currentDataSource;
      calculateTotals(filteredData);
    }
    setFilteredInfo(filters);
  }

  const clearFilters = () => {
    setFilteredInfo({});
  };

  const handleDelete = (key, index) => {
    setFilteredInfo((prev) => {
      const updatedFilteredInfo = { ...prev };
      const currentValue = updatedFilteredInfo[key];

      if (Array.isArray(currentValue)) {
        updatedFilteredInfo[key] = currentValue.filter((_, i) => i !== index);
      } else {
        delete updatedFilteredInfo[key];
      }

      return { ...updatedFilteredInfo };
    });
  };
  function handleSelect(params) {
    let data;

    if (params == "Unscanned") {
      data = dataSource?.filter(
        (val) =>
          val?.barcodeStatus == "HUB-OUTSCAN" ||
          val?.barcodeStatus == "INSCANNED_AT_TRANSIT"
      );
    } else if (params == "Scanned") {
      data = dataSource?.filter(
        (val) =>
          val?.barcodeStatus !== "HUB-OUTSCAN" ||
          val?.barcodeStatus == "INSCANNED_AT_TRANSIT"
      );
    } else {
      data = dataSource;
    }
    setTableData(data);
  }

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  return (
    <div className="p-2">
      <Heading
        title="LCR List"
        // buttonClick={() => setShowDamageBagModal(true)}
        //nextPageTitle={"DAMAGE BAG"}
        shipmentScanning={
          <>
            <ScannedShipment
              scannedData={totalBagsScanned}
              totalData={totalBags}
              type="bag"
            />
            <ScannedShipment
              scannedData={totalScanned}
              totalData={totalShipments}
            />
          </>
        }
      />
      <div className="consolidated-manifest-cntr">
        <div className="header" style={{ flexWrap: "wrap" }}>
          <div className="left">
            <Input
              placeholder="Enter Bag Id/AWB number to scan"
              bordered
              ref={inputRef}
              maxLength={14}
              onPressEnter={(e) => {
                if (e.target.value.length > 6) {
                  handleScan(e.target.value);
                } else {
                  message.error("Enter correct Bag Id/AWB number");
                }
              }}
              onKeyPress={(event) => {
                if (
                  checkNumbervalue(event) ||
                  checkSpace(event) ||
                  checkSpecialCharacter(event)
                ) {
                  event.preventDefault();
                }
              }}
              style={{
                borderRadius: "5px",
                height: "40px",
                margin: "15px 50px 15px 12px",
              }}
              // suffix={<img src={search_icon} />}
            />
          </div>
          <div className="align-center ">
            <RangePicker
              disabledDate={(current) => disabledDate(current)}
              onChange={handleDateChange}
              format={"DD/MM/YYYY"}
              defaultValue={[moment(), moment()]}
            />
            <div className="right">
              <Input
                placeholder="Enter keyword to search"
                bordered
                onChange={onSearch}
                // style={{ borderRadius: "5px", height: "40px", width: "400px" }}
                style={{
                  borderRadius: "5px",
                  height: "40px",
                  // width: "100%",
                  margin: "15px 12px",
                }}
                suffix={<img src={search_icon} />}
              />
            </div>
            <div className="select-type">
              <Select
                defaultValue={"Both"}
                style={{
                  width: "90px",
                }}
                placeholder="Select"
                onChange={handleSelect}
                options={[
                  {
                    value: "Scanned",
                    label: "Scanned",
                  },
                  {
                    value: "Unscanned",
                    label: "Unscanned",
                  },
                  {
                    value: "Both",
                    label: "Both",
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <div style={{ padding: " 0 1.3rem", marginTop: "5px" }}>
          <div className="d-flex" style={{ flexWrap: "wrap" }}>
            {Object.entries(filteredInfo).flatMap(([key, value]) =>
              Array.isArray(value) ? (
                value.map((item, index) => (
                  <div
                    key={`${key}_${index}`}
                    style={{
                      background: "rgb(226 226 226)",
                      // border: "2px solid",
                      borderRadius: "10px",
                      margin: "4px",
                      padding: "7px",
                      fontSize: "11px",
                    }}
                  >
                    {item !== null
                      ? key === "vehicleNum"
                        ? item
                        : capitalize(item)
                      : null}
                    <CloseCircleOutlined
                      style={{ margin: "0 5px" }}
                      onClick={() => handleDelete(key, index)}
                    />
                  </div>
                ))
              ) : value !== null ? (
                <div key={key}>
                  {value}
                  <button onClick={() => handleDelete(key)}>Delete</button>
                </div>
              ) : null
            )}
          </div>
          {Object.keys(filteredInfo)?.length !== 0 && (
            <Button onClick={clearFilters} className="cancel-btn mt-1 mb-1">
              Clear filters
            </Button>
          )}
        </div>
        <Table
          columns={columns}
          // className="lcr-inscan-container table-head-sticky"
          // scroll={{
          //   x: 500,
          // }}
          scroll={{
            x: 1500,
            y: 300,
          }}
          dataSource={[
            // eslint-disable-next-line no-unsafe-optional-chaining
            ...tableData?.sort(function (a, b) {
              return a?.isScanned ? 1 : b?.isScanned ? -1 : 0;
            }),
          ]}
          // scroll={{ x: 1300 }}
          // scroll={{ x: 1300 }}
          pagination={false}
          onChange={handleChange}
          loading={{
            indicator: (
              <img
                src={Smcs_Loader}
                style={{ height: "100px", width: "100px" }}
              />
            ),
            spinning: loading,
          }}
        />
      </div>
      {/* {damageBagModal(showDamageBagModal, setShowDamageBagModal)} */}
      {showBagModal && (
        <BagContentModal
          currentBarcode={currentBarCode}
          showModal={showBagModal}
          modalClose={closeModal}
        />
      )}
    </div>
  );
};

export default LCRScanList;
